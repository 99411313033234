const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addTraditionalCampaign = async (data) => {
  return await app.post("/traditional-campaigns", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getTraditionalCampaigns = async (path) => {
  return await app.get(`/traditional-campaigns/user/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getTraditionalCampaign = async (uuid) => {
  return await app.get(`/traditional-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editTraditionalCampaign = async (uuid, data) => {
  return await app.patch(`/traditional-campaigns/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteTraditionalCampaign = async (uuid) => {
  return await app.delete(`/traditional-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
