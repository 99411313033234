import { useState, useEffect } from "react";
import Pagination from "../../components/pagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Advertisers = ({ advertisers }) => {
  const [limit, setLimit] = useState(3);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  // Filter advertisers based on the keyword and calculate paginated data
  const filteredAdvertisers = advertisers.filter((advertiser) =>
    advertiser.name.toLowerCase().includes(keyword.toLowerCase())
  );

  // Update count based on filtered advertisers length
  useEffect(() => {
    setCount(filteredAdvertisers.length);
  }, [filteredAdvertisers]);

  // Calculate paginated data
  const paginatedAdvertisers = filteredAdvertisers.slice(
    (page - 1) * limit,
    page * limit
  );

  return (
    <div className="bg-white dark:bg-darkLight rounded-xl mt-4">
      <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
        <h1 className="font-bold text-lg">
          Advertisers ({filteredAdvertisers.length})
        </h1>
        <input
          onChange={(e) => setKeyword(e.target.value)}
          className="search-style"
          placeholder="Search here"
        />
      </div>
      <div className="px-6 py-5">
        <table className="w-full text-base">
          <thead>
            <tr>
              <th className="">Created At</th>
              <th className="">Name</th>
              <th className="">Email</th>
              <th className="">Phone</th>
              <th className="">SSP Campaigns</th>
              <th className="">DSP Campaigns</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody className="mt-2">
            {paginatedAdvertisers.map((item) => (
              <tr key={item.uuid}>
                <td className="">
                  {moment(item.createdAt).format("yyy, MMM DD")}
                </td>
                <td className="text-start py-4 hover:text-primary transition-all duration-200">
                  <button
                    onClick={() =>
                      navigate(`/advertiser-overview/?uuid=${item.uuid}`)
                    }
                  >
                    {item.name}
                  </button>
                </td>
                <td className="">{item.email}</td>
                <td className="">{item.phone}</td>
                <td className="">{item.SSPCampaigns.length}</td>
                <td className="">{item.DSPCampaigns.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination limit={limit} count={count} setPage={setPage} page={page} />
      </div>
    </div>
  );
};

export default Advertisers;
