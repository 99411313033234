const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addTraditionalCampaignReport = async (data) => {
  return await app.post("/traditional-campaign-reports", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const addTraditionalCampaignReportByCSV = async (data) => {
  return await app.post("/traditional-campaign-reports/csv", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
      "Content-Type": "multpart/form-data",
    },
  });
};
export const getTraditionalCampaignReports = async (path) => {
  return await app.get(`/traditional-campaign-reports/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getTraditionalCampaignChannelReports = async (path) => {
  return await app.get(`/traditional-campaign-reports/channel/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getSingleTraditionalCampaignReport = async (uuid) => {
  return await app.get(`/traditional-campaign-reports/single/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editTraditionalCampaignReport = async (uuid, data) => {
  return await app.patch(`/traditional-campaign-reports/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteTraditionalCampaignReport = async (uuid) => {
  return await app.delete(`/traditional-campaign-reports/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
