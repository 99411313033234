export const usage = [
  { date: "2024-03-04", users: 6 },
  { date: "2024-03-05", users: 5 },
  { date: "2024-03-06", users: 4 },
  { date: "2024-03-07", users: 6 },
  { date: "2024-03-08", users: 7 },
  { date: "2024-03-09", users: 5 },
  { date: "2024-03-10", users: 9 },
  { date: "2024-03-11", users: 8 },
  { date: "2024-03-12", users: 6 },
  { date: "2024-03-13", users: 3 },
  { date: "2024-03-14", users: 4 },
  { date: "2024-03-15", users: 6 },
  { date: "2024-03-16", users: 5 },
  { date: "2024-03-17", users: 3 },
];

export const iabCategories = [
  {
    category: "Arts & Entertainment",
    categories: [
      { name: "Books & Literature", value: "IAB1-1" },
      { name: "Celebrity Fan/Gossip", value: "IAB1-2" },
      { name: "Fine Art", value: "IAB1-3" },
      { name: "Humor", value: "IAB1-4" },
      { name: "Movies", value: "IAB1-5" },
      { name: "Music", value: "IAB1-6" },
      { name: "Television", value: "IAB1-7" },
    ],
  },
  {
    category: "Automotive",
    categories: [
      { name: "Auto Parts", value: "IAB2-1" },
      { name: "Auto Repair", value: "IAB2-2" },
      { name: "Buying/Selling Cars", value: "IAB2-3" },
      { name: "Car Culture", value: "IAB2-4" },
      { name: "Certified Pre-Owned", value: "IAB2-5" },
      { name: "Convertible", value: "IAB2-6" },
      { name: "Coupe", value: "IAB2-7" },
      { name: "Crossover", value: "IAB2-8" },
      { name: "Diesel", value: "IAB2-9" },
      { name: "Electric Vehicle", value: "IAB2-10" },
      { name: "Hatchback", value: "IAB2-11" },
      { name: "Hybrid", value: "IAB2-12" },
      { name: "Luxury", value: "IAB2-13" },
      { name: "MiniVan", value: "IAB2-14" },
      { name: "Mororcycles", value: "IAB2-15" },
      { name: "Off-Road Vehicles", value: "IAB2-16" },
      { name: "Performance Vehicles", value: "IAB2-17" },
      { name: "Pickup", value: "IAB2-18" },
      { name: "Road-Side Assistance", value: "IAB2-19" },
      { name: "Sedan", value: "IAB2-20" },
      { name: "Trucks & Accessories", value: "IAB2-21" },
      { name: "Vintage Cars", value: "IAB2-22" },
      { name: "Wagon", value: "IAB2-23" },
    ],
  },
  {
    category: "Business",
    categories: [
      { name: "Advertising", value: "IAB3-1" },
      { name: "Agriculture", value: "IAB3-2" },
      { name: "Biotech/Biomedical", value: "IAB3-3" },
      { name: "Business Software", value: "IAB3-4" },
      { name: "Construction", value: "IAB3-5" },
      { name: "Forestry", value: "IAB3-6" },
      { name: "Government", value: "IAB3-7" },
      { name: "Green Solutions", value: "IAB3-8" },
      { name: "Human Resources", value: "IAB3-9" },
      { name: "Logistics", value: "IAB3-10" },
      { name: "Marketing", value: "IAB3-11" },
      { name: "Metals", value: "IAB3-12" },
    ],
  },
  {
    category: "Careers",
    categories: [
      { name: "Career Planning", value: "IAB4-1" },
      { name: "College", value: "IAB4-2" },
      { name: "Financial Aid", value: "IAB4-3" },
      { name: "Job Fairs", value: "IAB4-4" },
      { name: "Job Search", value: "IAB4-5" },
      { name: "Resume Writing/Advice", value: "IAB4-6" },
      { name: "Nursing", value: "IAB4-7" },
      { name: "Scholarships", value: "IAB4-8" },
      { name: "Telecommuting", value: "IAB4-9" },
      { name: "U.S. Military", value: "IAB4-10" },
      { name: "Career Advice", value: "IAB4-11" },
    ],
  },
  {
    category: "Education",
    categories: [
      { name: "7-12 Education", value: "IAB5-1" },
      { name: "Adult Education", value: "IAB5-2" },
      { name: "Art History", value: "IAB5-3" },
      { name: "College Administration", value: "IAB5-4" },
      { name: "College Life", value: "IAB5-5" },
      { name: "Distance Learning", value: "IAB5-6" },
      { name: "English as a 2nd Language", value: "IAB5-7" },
      { name: "Language Learning", value: "IAB5-8" },
      { name: "Graduate School", value: "IAB5-9" },
      { name: "Homeschooling", value: "IAB5-10" },
      { name: "Homework/Study Tips", value: "IAB5-11" },
      { name: "K-6 Educators", value: "IAB5-12" },
      { name: "Private School", value: "IAB5-13" },
      { name: "Special Education", value: "IAB5-14" },
      { name: "Studying Business", value: "IAB5-15" },
    ],
  },
  {
    category: "Family & Parenting",
    categories: [
      { name: "Adoption", value: "IAB6-1" },
      { name: "Babies & Toddlers", value: "IAB6-2" },
      { name: "Daycare/Pre School", value: "IAB6-3" },
      { name: "Family Internet", value: "IAB6-4" },
      { name: "Parenting - K-6 Kids", value: "IAB6-5" },
      { name: "Parenting teens", value: "IAB6-6" },
      { name: "Pregnancy", value: "IAB6-7" },
      { name: "Special Needs Kids", value: "IAB6-8" },
      { name: "Eldercare", value: "IAB6-9" },
    ],
  },
  {
    category: "Health & Fitness",
    categories: [
      { name: "Exercise", value: "IAB7-1" },
      { name: "A.D.D.", value: "IAB7-2" },
      { name: "AIDS/HIV", value: "IAB7-3" },
      { name: "Allergies", value: "IAB7-4" },
      { name: "Alternative Medicine", value: "IAB7-5" },
      { name: "Arthritis", value: "IAB7-6" },
      { name: "Asthma", value: "IAB7-7" },
      { name: "Autism/PDD", value: "IAB7-8" },
      { name: "Bipolar Disorder", value: "IAB7-9" },
      { name: "Brain Tumor", value: "IAB7-10" },
      { name: "Cancer", value: "IAB7-11" },
      { name: "Cholesterol", value: "IAB7-12" },
      { name: "Chronic Fatigue Syndrome", value: "IAB7-13" },
      { name: "Chronic Pain", value: "IAB7-14" },
      { name: "Cold & Flu", value: "IAB7-15" },
      { name: "Deafness", value: "IAB7-16" },
      { name: "Dental Care", value: "IAB7-17" },
      { name: "Depression", value: "IAB7-18" },
      { name: "Dermatology", value: "IAB7-19" },
      { name: "Diabetes", value: "IAB7-20" },
      { name: "Epilepsy", value: "IAB7-21" },
      { name: "GERD/Acid Reflux", value: "IAB7-22" },
      { name: "Headaches/Migraines", value: "IAB7-23" },
      { name: "Heart Disease", value: "IAB7-24" },
      { name: "Herbs for Health", value: "IAB7-25" },
      { name: "Holistic Healing", value: "IAB7-26" },
      { name: "IBS/Crohn's Disease", value: "IAB7-27" },
      { name: "Incest/Abuse Support", value: "IAB7-28" },
      { name: "Incontinence", value: "IAB7-29" },
      { name: "Infertility", value: "IAB7-30" },
      { name: "Men's Health", value: "IAB7-31" },
      { name: "Nutrition", value: "IAB7-32" },
      { name: "Orthopedics", value: "IAB7-33" },
      { name: "Panic/Anxiety Disorders", value: "IAB7-34" },
      { name: "Pediatrics", value: "IAB7-35" },
      { name: "Physical Therapy", value: "IAB7-36" },
      { name: "Psychology/Psychiatry", value: "IAB7-37" },
      { name: "Senior Health", value: "IAB7-38" },
      { name: "Sexuality", value: "IAB7-39" },
      { name: "Sleep Disorders", value: "IAB7-40" },
      { name: "Smoking Cessation", value: "IAB7-41" },
      { name: "Substance Abuse", value: "IAB7-42" },
      { name: "Thyroid Disease", value: "IAB7-43" },
      { name: "Weight Loss", value: "IAB7-44" },
      { name: "Women's Health", value: "IAB7-45" },
    ],
  },
  {
    category: "Food & Drink",
    categories: [
      { name: "American Cuisine", value: "IAB8-1" },
      { name: "Barbecues & Grilling", value: "IAB8-2" },
      { name: "Cajun/Creole", value: "IAB8-3" },
      { name: "Chinese Cuisine", value: "IAB8-4" },
      { name: "Cocktails/Beer", value: "IAB8-5" },
      { name: "Coffee/Tea", value: "IAB8-6" },
      { name: "Cuisine-Specific", value: "IAB8-7" },
      { name: "Desserts & Baking", value: "IAB8-8" },
      { name: "Dining Out", value: "IAB8-9" },
      { name: "Food Allergies", value: "IAB8-10" },
      { name: "French Cuisine", value: "IAB8-11" },
      { name: "Health/Lowfat Cooking", value: "IAB8-12" },
      { name: "Italian Cuisine", value: "IAB8-13" },
      { name: "Japanese Cuisine", value: "IAB8-14" },
      { name: "Mexican Cuisine", value: "IAB8-15" },
      { name: "Vegan", value: "IAB8-16" },
      { name: "Vegetarian", value: "IAB8-17" },
      { name: "Wine", value: "IAB8-18" },
    ],
  },
  {
    category: "Hobbies & Interests",
    categories: [
      { name: "Art/Technology", value: "IAB9-1" },
      { name: "Arts & Crafts", value: "IAB9-2" },
      { name: "Beadwork", value: "IAB9-3" },
      { name: "Bird-Watching", value: "IAB9-4" },
      { name: "Board Games/Puzzles", value: "IAB9-5" },
      { name: "Candle & Soap Making", value: "IAB9-6" },
      { name: "Card Games", value: "IAB9-7" },
      { name: "Chess", value: "IAB9-8" },
      { name: "Cigars", value: "IAB9-9" },
      { name: "Collecting", value: "IAB9-10" },
      { name: "Comic Books", value: "IAB9-11" },
      { name: "Drawing/Sketching", value: "IAB9-12" },
      { name: "Freelance Writing", value: "IAB9-13" },
      { name: "Genealogy", value: "IAB9-14" },
      { name: "Getting Published", value: "IAB9-15" },
      { name: "Guitar", value: "IAB9-16" },
      { name: "Home Recording", value: "IAB9-17" },
      { name: "Investors & Patents", value: "IAB9-18" },
      { name: "Jewelry-Making", value: "IAB9-19" },
      { name: "Magic & Illusion", value: "IAB9-20" },
      { name: "Needlework", value: "IAB9-21" },
      { name: "Painting", value: "IAB9-22" },
      { name: "Photography", value: "IAB9-23" },
      { name: "Radio", value: "IAB9-24" },
      { name: "Roleplaying Games", value: "IAB9-25" },
      { name: "Sci-Fi & Fantasy", value: "IAB9-26" },
      { name: "Scrapbooking", value: "IAB9-27" },
      { name: "Screenwriting", value: "IAB9-28" },
      { name: "Stamps & Coins", value: "IAB9-29" },
      { name: "Video & Computer Games", value: "IAB9-30" },
      { name: "Woodworking", value: "IAB9-31" },
    ],
  },
  {
    category: "Home & Garden",
    categories: [
      { name: "Appliances", value: "IAB10-1" },
      { name: "Entertaining", value: "IAB10-2" },
      { name: "Environmental Safety", value: "IAB10-3" },
      { name: "Gardening", value: "IAB10-4" },
      { name: "Home Repair", value: "IAB10-5" },
      { name: "Home Theater", value: "IAB10-6" },
      { name: "Interior Decorating", value: "IAB10-7" },
      { name: "Landscaping", value: "IAB10-8" },
      { name: "Remodeling & Construction", value: "IAB10-9" },
    ],
  },
  {
    category: "Law, Gov't & Politics",
    categories: [
      { name: "Immigration", value: "IAB11-1" },
      { name: "Legal Issues", value: "IAB11-2" },
      { name: "U.S. Government Resources", value: "IAB11-3" },
      { name: "Politics", value: "IAB11-4" },
      { name: "Commentary", value: "IAB11-5" },
    ],
  },
  {
    category: "News",
    categories: [
      { name: "International News", value: "IAB12-1" },
      { name: "National News", value: "IAB12-2" },
      { name: "Local News", value: "IAB12-3" },
    ],
  },
  {
    category: "Personal Finance",
    categories: [
      { name: "Beginning Investing", value: "IAB13-1" },
      { name: "Credit/Debt & Loans", value: "IAB13-2" },
      { name: "Financial News", value: "IAB13-3" },
      { name: "Financial Planning", value: "IAB13-4" },
      { name: "Hedge Fund", value: "IAB13-5" },
      { name: "Insurance", value: "IAB13-6" },
      { name: "Investing", value: "IAB13-7" },
      { name: "Mutual Funds", value: "IAB13-8" },
      { name: "Options", value: "IAB13-9" },
      { name: "Retirement Planning", value: "IAB13-10" },
      { name: "Stocks", value: "IAB13-11" },
      { name: "Tax Planning", value: "IAB13-12" },
    ],
  },
  {
    category: "Society",
    categories: [
      { name: "Dating", value: "IAB14-1" },
      { name: "Divorce Support", value: "IAB14-2" },
      { name: "Gay Life", value: "IAB14-3" },
      { name: "Marriage", value: "IAB14-4" },
      { name: "Senior Living", value: "IAB14-5" },
      { name: "Teens", value: "IAB14-6" },
      { name: "Weddings", value: "IAB14-7" },
      { name: "Ethnic Specific", value: "IAB14-8" },
    ],
  },
  {
    category: "Science",
    categories: [
      { name: "Astrology", value: "IAB15-1" },
      { name: "Biology", value: "IAB15-2" },
      { name: "Chemistry", value: "IAB15-3" },
      { name: "Geology", value: "IAB15-4" },
      { name: "Paranormal Phenomena", value: "IAB15-5" },
      { name: "Physics", value: "IAB15-6" },
      { name: "Space/Astronomy", value: "IAB15-7" },
      { name: "Geography", value: "IAB15-8" },
      { name: "Botany", value: "IAB15-9" },
      { name: "Weather", value: "IAB15-10" },
    ],
  },
  {
    category: "Pets",
    categories: [
      { name: "Aquariums", value: "IAB16-1" },
      { name: "Birds", value: "IAB16-2" },
      { name: "Cats", value: "IAB16-3" },
      { name: "Dogs", value: "IAB16-4" },
      { name: "Large Animals", value: "IAB16-5" },
      { name: "Reptiles", value: "IAB16-6" },
      { name: "Veterinary Medicine", value: "IAB16-7" },
    ],
  },
  {
    category: "Sports",
    categories: [
      { name: "Auto Racing", value: "IAB17-1" },
      { name: "Baseball", value: "IAB17-2" },
      { name: "Bicycling", value: "IAB17-3" },
      { name: "Bodybuilding", value: "IAB17-4" },
      { name: "Boxing", value: "IAB17-5" },
      { name: "Canoeing/Kayaking", value: "IAB17-6" },
      { name: "Cheerleading", value: "IAB17-7" },
      { name: "Climbing", value: "IAB17-8" },
      { name: "Cricket", value: "IAB17-9" },
      { name: "Figure Skating", value: "IAB17-10" },
      { name: "Fly Fishing", value: "IAB17-11" },
      { name: "Football", value: "IAB17-12" },
      { name: "Freshwater Fishing", value: "IAB17-13" },
      { name: "Game & Fish", value: "IAB17-14" },
      { name: "Golf", value: "IAB17-15" },
      { name: "Horse Racing", value: "IAB17-16" },
      { name: "Horses", value: "IAB17-17" },
      { name: "Hunting/Shooting", value: "IAB17-18" },
      { name: "Inline Skating", value: "IAB17-19" },
      { name: "Martial Arts", value: "IAB17-20" },
      { name: "Mountain Biking", value: "IAB17-21" },
      { name: "NASCAR Racing", value: "IAB17-22" },
      { name: "Olympics", value: "IAB17-23" },
      { name: "Paintball", value: "IAB17-24" },
      { name: "Power & Motoryachts", value: "IAB17-25" },
      { name: "Pro Basketball", value: "IAB17-26" },
      { name: "Pro Ice Hockey", value: "IAB17-27" },
      { name: "Rodeo", value: "IAB17-28" },
      { name: "Rugby", value: "IAB17-29" },
      { name: "Running/Jogging", value: "IAB17-30" },
      { name: "Sailing", value: "IAB17-31" },
      { name: "Saltwater Fishing", value: "IAB17-32" },
      { name: "Scuba Diving", value: "IAB17-33" },
      { name: "Skateboarding", value: "IAB17-34" },
      { name: "Skiing", value: "IAB17-35" },
      { name: "Snowboarding", value: "IAB17-36" },
      { name: "Surfing/Body-Boarding", value: "IAB17-37" },
      { name: "Swimming", value: "IAB17-38" },
      { name: "Table Tennis/Ping-Pong", value: "IAB17-39" },
      { name: "Tennis", value: "IAB17-40" },
      { name: "Volleyball", value: "IAB17-41" },
      { name: "Walking", value: "IAB17-42" },
      { name: "Waterski/Wakeboard", value: "IAB17-43" },
      { name: "World Soccer", value: "IAB17-44" },
    ],
  },
  {
    category: "Style & Fashion",
    categories: [
      { name: "Accessories", value: "IAB18-1" },
      { name: "Beauty", value: "IAB18-2" },
      { name: "Body Art", value: "IAB18-3" },
      { name: "Fashion", value: "IAB18-4" },
      { name: "Jewelry", value: "IAB18-5" },
      { name: "Clothing", value: "IAB18-6" },
    ],
  },
  {
    category: "Technology & Computing",
    categories: [
      { name: "3-D Graphics", value: "IAB19-1" },
      { name: "Animation", value: "IAB19-2" },
      { name: "Antivirus Software", value: "IAB19-3" },
      { name: "C/C++", value: "IAB19-4" },
      { name: "Cameras & Camcorders", value: "IAB19-5" },
      { name: "Cell Phones", value: "IAB19-6" },
      { name: "Computer Certification", value: "IAB19-7" },
      { name: "Computer Networking", value: "IAB19-8" },
      { name: "Computer Peripherals", value: "IAB19-9" },
      { name: "Computer Reviews", value: "IAB19-10" },
      { name: "Data Centers", value: "IAB19-11" },
      { name: "Databases", value: "IAB19-12" },
      { name: "Desktop Publishing", value: "IAB19-13" },
      { name: "Desktop Video", value: "IAB19-14" },
      { name: "Email", value: "IAB19-15" },
      { name: "Graphics Software", value: "IAB19-16" },
      { name: "Home Video/DVD", value: "IAB19-17" },
      { name: "Internet Technology", value: "IAB19-18" },
      { name: "Java", value: "IAB19-19" },
      { name: "JavaScript", value: "IAB19-20" },
      { name: "Mac Support", value: "IAB19-21" },
      { name: "MP3/MIDI", value: "IAB19-22" },
      { name: "Net Conferencing", value: "IAB19-23" },
      { name: "Net for Beginners", value: "IAB19-24" },
      { name: "Network Security", value: "IAB19-25" },
      { name: "Palmtops/PDAs", value: "IAB19-26" },
      { name: "PC Support", value: "IAB19-27" },
      { name: "Portable", value: "IAB19-28" },
      { name: "Shareware/Freeware", value: "IAB19-29" },
      { name: "Unix", value: "IAB19-30" },
      { name: "Visual Basic", value: "IAB19-31" },
      { name: "Web Clip Art", value: "IAB19-32" },
      { name: "Web Design/HTML", value: "IAB19-33" },
      { name: "Web Search", value: "IAB19-34" },
      { name: "Windows", value: "IAB19-35" },
    ],
  },
  {
    category: "Travel",
    categories: [
      { name: "Adventure Travel", value: "IAB20-1" },
      { name: "Africa", value: "IAB20-2" },
      { name: "Air Travel", value: "IAB20-3" },
      { name: "Australia & New Zealand", value: "IAB20-4" },
      { name: "Bed & Breakfasts", value: "IAB20-5" },
      { name: "Budget Travel", value: "IAB20-6" },
      { name: "Business Travel", value: "IAB20-7" },
      { name: "By US Locale", value: "IAB20-8" },
      { name: "Camping", value: "IAB20-9" },
      { name: "Canada", value: "IAB20-10" },
      { name: "Caribbean", value: "IAB20-11" },
      { name: "Cruises", value: "IAB20-12" },
      { name: "Eastern Europe", value: "IAB20-13" },
      { name: "Europe", value: "IAB20-14" },
      { name: "France", value: "IAB20-15" },
      { name: "Greece", value: "IAB20-16" },
      { name: "Honeymoons/Getaways", value: "IAB20-17" },
      { name: "Hotels", value: "IAB20-18" },
      { name: "Italy", value: "IAB20-19" },
      { name: "Japan", value: "IAB20-20" },
      { name: "Mexico & Central America", value: "IAB20-21" },
      { name: "National Parks", value: "IAB20-22" },
      { name: "South America", value: "IAB20-23" },
      { name: "Spas", value: "IAB20-24" },
      { name: "Theme Parks", value: "IAB20-25" },
      { name: "Traveling with Kids", value: "IAB20-26" },
      { name: "United Kingdom", value: "IAB20-27" },
    ],
  },
  {
    category: "Real Estate",
    categories: [
      { name: "Apartments", value: "IAB21-1" },
      { name: "Architects", value: "IAB21-2" },
      { name: "Buying/Selling Homes", value: "IAB21-3" },
    ],
  },
  {
    category: "Shopping",
    categories: [
      { name: "Contests & Freebies", value: "IAB22-1" },
      { name: "Couponing", value: "IAB22-2" },
      { name: "Comparison", value: "IAB22-3" },
    ],
  },
  {
    category: "Religion & Spirituality",
    categories: [
      { name: "Alternative Religions", value: "IAB23-1" },
      { name: "Atheism/Agnosticism", value: "IAB23-2" },
      { name: "Buddhism", value: "IAB23-3" },
      { name: "Catholicism", value: "IAB23-4" },
      { name: "Christianity", value: "IAB23-5" },
      { name: "Hinduism", value: "IAB23-6" },
      { name: "Islam", value: "IAB23-7" },
      { name: "Judaism", value: "IAB23-8" },
      { name: "Latter-Day Saints", value: "IAB23-9" },
      { name: "Pagan/Wiccan", value: "IAB23-10" },
    ],
  },
  {
    category: "Uncategorized",
    categories: [{ name: "Uncategorized", value: "IAB24" }],
  },
  {
    category: "Non-Standard Content",
    categories: [
      { name: "Illegal Content", value: "IAB25-1" },
      { name: "Warez", value: "IAB25-2" },
      { name: "Spyware/Malware", value: "IAB25-3" },
      { name: "Copyright Infringement", value: "IAB25-4" },
    ],
  },
];

export const countries = [
  {
    label: "Afghanistan",
    code: "AF",
    iso3: "AFG",
    numeric: "004",
  },
  {
    label: "South Africa",
    code: "ZA",
    iso3: "ZAF",
    numeric: "710",
  },
  {
    label: "Åland Islands",
    code: "AX",
    iso3: "ALA",
    numeric: "248",
  },
  {
    label: "Albania",
    code: "AL",
    iso3: "ALB",
    numeric: "008",
  },
  {
    label: "Algeria",
    code: "DZ",
    iso3: "DZA",
    numeric: "012",
  },
  {
    label: "Germany",
    code: "DE",
    iso3: "DEU",
    numeric: "276",
  },
  {
    label: "Andorra",
    code: "AD",
    iso3: "AND",
    numeric: "020",
  },
  {
    label: "Angola",
    code: "AO",
    iso3: "AGO",
    numeric: "024",
  },
  {
    label: "Anguilla",
    code: "AI",
    iso3: "AIA",
    numeric: "660",
  },
  {
    label: "Antarctica",
    code: "AQ",
    iso3: "ATA",
    numeric: "010",
  },
  {
    label: "Antigua and Barbuda",
    code: "AG",
    iso3: "ATG",
    numeric: "028",
  },
  {
    label: "Saudi Arabia",
    code: "SA",
    iso3: "SAU",
    numeric: "682",
  },
  {
    label: "Argentina",
    code: "AR",
    iso3: "ARG",
    numeric: "032",
  },
  {
    label: "Armenia",
    code: "AM",
    iso3: "ARM",
    numeric: "051",
  },
  {
    label: "Aruba",
    code: "AW",
    iso3: "ABW",
    numeric: "533",
  },
  {
    label: "Australia",
    code: "AU",
    iso3: "AUS",
    numeric: "036",
  },
  {
    label: "Austria",
    code: "AT",
    iso3: "AUT",
    numeric: "040",
  },
  {
    label: "Azerbaijan",
    code: "AZ",
    iso3: "AZE",
    numeric: "031",
  },
  {
    label: "Bahamas (the)",
    code: "BS",
    iso3: "BHS",
    numeric: "044",
  },
  {
    label: "Bahrain",
    code: "BH",
    iso3: "BHR",
    numeric: "048",
  },
  {
    label: "Bangladesh",
    code: "BD",
    iso3: "BGD",
    numeric: "050",
  },
  {
    label: "Barbados",
    code: "BB",
    iso3: "BRB",
    numeric: "052",
  },
  {
    label: "Belarus",
    code: "BY",
    iso3: "BLR",
    numeric: "112",
  },
  {
    label: "Belgium",
    code: "BE",
    iso3: "BEL",
    numeric: "056",
  },
  {
    label: "Belize",
    code: "BZ",
    iso3: "BLZ",
    numeric: "084",
  },
  {
    label: "Benin",
    code: "BJ",
    iso3: "BEN",
    numeric: "204",
  },
  {
    label: "Bermuda",
    code: "BM",
    iso3: "BMU",
    numeric: "060",
  },
  {
    label: "Bhutan",
    code: "BT",
    iso3: "BTN",
    numeric: "064",
  },
  {
    label: "Bolivia (Plurinational State of)",
    code: "BO",
    iso3: "BOL",
    numeric: "068",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    iso3: "BES",
    numeric: "535",
  },
  {
    label: "Bosnia and Herzegovina",
    code: "BA",
    iso3: "BIH",
    numeric: "070",
  },
  {
    label: "Botswana",
    code: "BW",
    iso3: "BWA",
    numeric: "072",
  },
  {
    label: "Bouvet Island",
    code: "BV",
    iso3: "BVT",
    numeric: "074",
  },
  {
    label: "Brazil",
    code: "BR",
    iso3: "BRA",
    numeric: "076",
  },
  {
    label: "Brunei Darussalam",
    code: "BN",
    iso3: "BRN",
    numeric: "096",
  },
  {
    label: "Bulgaria",
    code: "BG",
    iso3: "BGR",
    numeric: "100",
  },
  {
    label: "Burkina Faso",
    code: "BF",
    iso3: "BFA",
    numeric: "854",
  },
  {
    label: "Burundi",
    code: "BI",
    iso3: "BDI",
    numeric: "108",
  },
  {
    label: "Cabo Verde",
    code: "CV",
    iso3: "CPV",
    numeric: "132",
  },
  {
    label: "Cayman Islands (the)",
    code: "KY",
    iso3: "CYM",
    numeric: "136",
  },
  {
    label: "Cambodia",
    code: "KH",
    iso3: "KHM",
    numeric: "116",
  },
  {
    label: "Cameroon",
    code: "CM",
    iso3: "CMR",
    numeric: "120",
  },
  {
    label: "Canada",
    code: "CA",
    iso3: "CAN",
    numeric: "124",
  },
  {
    label: "Chile",
    code: "CL",
    iso3: "CHL",
    numeric: "152",
  },
  {
    label: "China",
    code: "CN",
    iso3: "CHN",
    numeric: "156",
  },
  {
    label: "Christmas Island",
    code: "CX",
    iso3: "CXR",
    numeric: "162",
  },
  {
    label: "Cyprus",
    code: "CY",
    iso3: "CYP",
    numeric: "196",
  },
  {
    label: "Cocos (Keeling) Islands (the)",
    code: "CC",
    iso3: "CCK",
    numeric: "166",
  },
  {
    label: "Colombia",
    code: "CO",
    iso3: "COL",
    numeric: "170",
  },
  {
    label: "Comoros (the)",
    code: "KM",
    iso3: "COM",
    numeric: "174",
  },
  {
    label: "Congo (the Democratic Republic of the)",
    code: "CD",
    iso3: "COD",
    numeric: "180",
  },
  {
    label: "Congo (the)",
    code: "CG",
    iso3: "COG",
    numeric: "178",
  },
  {
    label: "Cook Islands (the)",
    code: "CK",
    iso3: "COK",
    numeric: "184",
  },
  {
    label: "Korea (the Republic of)",
    code: "KR",
    iso3: "KOR",
    numeric: "410",
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    code: "KP",
    iso3: "PRK",
    numeric: "408",
  },
  {
    label: "Costa Rica",
    code: "CR",
    iso3: "CRI",
    numeric: "188",
  },
  {
    label: "Côte d'Ivoire",
    code: "CI",
    iso3: "CIV",
    numeric: "384",
  },
  {
    label: "Croatia",
    code: "HR",
    iso3: "HRV",
    numeric: "191",
  },
  {
    label: "Cuba",
    code: "CU",
    iso3: "CUB",
    numeric: "192",
  },
  {
    label: "Curaçao",
    code: "CW",
    iso3: "CUW",
    numeric: "531",
  },
  {
    label: "Denmark",
    code: "DK",
    iso3: "DNK",
    numeric: "208",
  },
  {
    label: "Djibouti",
    code: "DJ",
    iso3: "DJI",
    numeric: "262",
  },
  {
    label: "Dominican Republic (the)",
    code: "DO",
    iso3: "DOM",
    numeric: "214",
  },
  {
    label: "Dominica",
    code: "DM",
    iso3: "DMA",
    numeric: "212",
  },
  {
    label: "Egypt",
    code: "EG",
    iso3: "EGY",
    numeric: "818",
  },
  {
    label: "El Salvador",
    code: "SV",
    iso3: "SLV",
    numeric: "222",
  },
  {
    label: "United Arab Emirates (the)",
    code: "AE",
    iso3: "ARE",
    numeric: "784",
  },
  {
    label: "Ecuador",
    code: "EC",
    iso3: "ECU",
    numeric: "218",
  },
  {
    label: "Eritrea",
    code: "ER",
    iso3: "ERI",
    numeric: "232",
  },
  {
    label: "Spain",
    code: "ES",
    iso3: "ESP",
    numeric: "724",
  },
  {
    label: "Estonia",
    code: "EE",
    iso3: "EST",
    numeric: "233",
  },
  {
    label: "United States of America (the)",
    code: "US",
    iso3: "USA",
    numeric: "840",
  },
  {
    label: "Ethiopia",
    code: "ET",
    iso3: "ETH",
    numeric: "231",
  },
  {
    label: "Falkland Islands (the) [Malvinas]",
    code: "FK",
    iso3: "FLK",
    numeric: "238",
  },
  {
    label: "Faroe Islands (the)",
    code: "FO",
    iso3: "FRO",
    numeric: "234",
  },
  {
    label: "Fiji",
    code: "FJ",
    iso3: "FJI",
    numeric: "242",
  },
  {
    label: "Finland",
    code: "FI",
    iso3: "FIN",
    numeric: "246",
  },
  {
    label: "France",
    code: "FR",
    iso3: "FRA",
    numeric: "250",
  },
  {
    label: "Gabon",
    code: "GA",
    iso3: "GAB",
    numeric: "266",
  },
  {
    label: "Gambia (the)",
    code: "GM",
    iso3: "GMB",
    numeric: "270",
  },
  {
    label: "Georgia",
    code: "GE",
    iso3: "GEO",
    numeric: "268",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    code: "GS",
    iso3: "SGS",
    numeric: "239",
  },
  {
    label: "Ghana",
    code: "GH",
    iso3: "GHA",
    numeric: "288",
  },
  {
    label: "Gibraltar",
    code: "GI",
    iso3: "GIB",
    numeric: "292",
  },
  {
    label: "Greece",
    code: "GR",
    iso3: "GRC",
    numeric: "300",
  },
  {
    label: "Grenada",
    code: "GD",
    iso3: "GRD",
    numeric: "308",
  },
  {
    label: "Greenland",
    code: "GL",
    iso3: "GRL",
    numeric: "304",
  },
  {
    label: "Guadeloupe",
    code: "GP",
    iso3: "GLP",
    numeric: "312",
  },
  {
    label: "Guam",
    code: "GU",
    iso3: "GUM",
    numeric: "316",
  },
  {
    label: "Guatemala",
    code: "GT",
    iso3: "GTM",
    numeric: "320",
  },
  {
    label: "Guernsey",
    code: "GG",
    iso3: "GGY",
    numeric: "831",
  },
  {
    label: "Guinea",
    code: "GN",
    iso3: "GIN",
    numeric: "324",
  },
  {
    label: "Equatorial Guinea",
    code: "GQ",
    iso3: "GNQ",
    numeric: "226",
  },
  {
    label: "Guinea-Bissau",
    code: "GW",
    iso3: "GNB",
    numeric: "624",
  },
  {
    label: "Guyana",
    code: "GY",
    iso3: "GUY",
    numeric: "328",
  },
  {
    label: "French Guiana",
    code: "GF",
    iso3: "GUF",
    numeric: "254",
  },
  {
    label: "Haiti",
    code: "HT",
    iso3: "HTI",
    numeric: "332",
  },
  {
    label: "Heard Island and McDonald Islands",
    code: "HM",
    iso3: "HMD",
    numeric: "334",
  },
  {
    label: "Honduras",
    code: "HN",
    iso3: "HND",
    numeric: "340",
  },
  {
    label: "Hong Kong",
    code: "HK",
    iso3: "HKG",
    numeric: "344",
  },
  {
    label: "Hungary",
    code: "HU",
    iso3: "HUN",
    numeric: "348",
  },
  {
    label: "Isle of Man",
    code: "IM",
    iso3: "IMN",
    numeric: "833",
  },
  {
    label: "United States Minor Outlying Islands (the)",
    code: "UM",
    iso3: "UMI",
    numeric: "581",
  },
  {
    label: "India",
    code: "IN",
    iso3: "IND",
    numeric: "356",
  },
  {
    label: "British Indian Ocean Territory (the)",
    code: "IO",
    iso3: "IOT",
    numeric: "086",
  },
  {
    label: "Indonesia",
    code: "ID",
    iso3: "IDN",
    numeric: "360",
  },
  {
    label: "Iran (Islamic Republic of)",
    code: "IR",
    iso3: "IRN",
    numeric: "364",
  },
  {
    label: "Iraq",
    code: "IQ",
    iso3: "IRQ",
    numeric: "368",
  },
  {
    label: "Ireland",
    code: "IE",
    iso3: "IRL",
    numeric: "372",
  },
  {
    label: "Iceland",
    code: "IS",
    iso3: "ISL",
    numeric: "352",
  },
  {
    label: "Israel",
    code: "IL",
    iso3: "ISR",
    numeric: "376",
  },
  {
    label: "Italy",
    code: "IT",
    iso3: "ITA",
    numeric: "380",
  },
  {
    label: "Jamaica",
    code: "JM",
    iso3: "JAM",
    numeric: "388",
  },
  {
    label: "Japan",
    code: "JP",
    iso3: "JPN",
    numeric: "392",
  },
  {
    label: "Jersey",
    code: "JE",
    iso3: "JEY",
    numeric: "832",
  },
  {
    label: "Jordan",
    code: "JO",
    iso3: "JOR",
    numeric: "400",
  },
  {
    label: "Kazakhstan",
    code: "KZ",
    iso3: "KAZ",
    numeric: "398",
  },
  {
    label: "Kenya",
    code: "KE",
    iso3: "KEN",
    numeric: "404",
  },
  {
    label: "Kyrgyzstan",
    code: "KG",
    iso3: "KGZ",
    numeric: "417",
  },
  {
    label: "Kiribati",
    code: "KI",
    iso3: "KIR",
    numeric: "296",
  },
  {
    label: "Kuwait",
    code: "KW",
    iso3: "KWT",
    numeric: "414",
  },
  {
    label: "Lao People's Democratic Republic (the)",
    code: "LA",
    iso3: "LAO",
    numeric: "418",
  },
  {
    label: "Lesotho",
    code: "LS",
    iso3: "LSO",
    numeric: "426",
  },
  {
    label: "Latvia",
    code: "LV",
    iso3: "LVA",
    numeric: "428",
  },
  {
    label: "Lebanon",
    code: "LB",
    iso3: "LBN",
    numeric: "422",
  },
  {
    label: "Liberia",
    code: "LR",
    iso3: "LBR",
    numeric: "430",
  },
  {
    label: "Libya",
    code: "LY",
    iso3: "LBY",
    numeric: "434",
  },
  {
    label: "Liechtenstein",
    code: "LI",
    iso3: "LIE",
    numeric: "438",
  },
  {
    label: "Lithuania",
    code: "LT",
    iso3: "LTU",
    numeric: "440",
  },
  {
    label: "Luxembourg",
    code: "LU",
    iso3: "LUX",
    numeric: "442",
  },
  {
    label: "Macao",
    code: "MO",
    iso3: "MAC",
    numeric: "446",
  },
  {
    label: "Macedonia (the former Yugoslav Republic of)",
    code: "MK",
    iso3: "MKD",
    numeric: "807",
  },
  {
    label: "Madagascar",
    code: "MG",
    iso3: "MDG",
    numeric: "450",
  },
  {
    label: "Malaysia",
    code: "MY",
    iso3: "MYS",
    numeric: "458",
  },
  {
    label: "Malawi",
    code: "MW",
    iso3: "MWI",
    numeric: "454",
  },
  {
    label: "Maldives",
    code: "MV",
    iso3: "MDV",
    numeric: "462",
  },
  {
    label: "Mali",
    code: "ML",
    iso3: "MLI",
    numeric: "466",
  },
  {
    label: "Malta",
    code: "MT",
    iso3: "MLT",
    numeric: "470",
  },
  {
    label: "Northern Mariana Islands (the)",
    code: "MP",
    iso3: "MNP",
    numeric: "580",
  },
  {
    label: "Morocco",
    code: "MA",
    iso3: "MAR",
    numeric: "504",
  },
  {
    label: "Marshall Islands (the)",
    code: "MH",
    iso3: "MHL",
    numeric: "584",
  },
  {
    label: "Martinique",
    code: "MQ",
    iso3: "MTQ",
    numeric: "474",
  },
  {
    label: "Mauritius",
    code: "MU",
    iso3: "MUS",
    numeric: "480",
  },
  {
    label: "Mauritania",
    code: "MR",
    iso3: "MRT",
    numeric: "478",
  },
  {
    label: "Mayotte",
    code: "YT",
    iso3: "MYT",
    numeric: "175",
  },
  {
    label: "Mexico",
    code: "MX",
    iso3: "MEX",
    numeric: "484",
  },
  {
    label: "Micronesia (Federated States of)",
    code: "FM",
    iso3: "FSM",
    numeric: "583",
  },
  {
    label: "Moldova (the Republic of)",
    code: "MD",
    iso3: "MDA",
    numeric: "498",
  },
  {
    label: "Monaco",
    code: "MC",
    iso3: "MCO",
    numeric: "492",
  },
  {
    label: "Mongolia",
    code: "MN",
    iso3: "MNG",
    numeric: "496",
  },
  {
    label: "Montenegro",
    code: "ME",
    iso3: "MNE",
    numeric: "499",
  },
  {
    label: "Montserrat",
    code: "MS",
    iso3: "MSR",
    numeric: "500",
  },
  {
    label: "Mozambique",
    code: "MZ",
    iso3: "MOZ",
    numeric: "508",
  },
  {
    label: "Myanmar",
    code: "MM",
    iso3: "MMR",
    numeric: "104",
  },
  {
    label: "Namibia",
    code: "NA",
    iso3: "NAM",
    numeric: "516",
  },
  {
    label: "Nauru",
    code: "NR",
    iso3: "NRU",
    numeric: "520",
  },
  {
    label: "Nepal",
    code: "NP",
    iso3: "NPL",
    numeric: "524",
  },
  {
    label: "Nicaragua",
    code: "NI",
    iso3: "NIC",
    numeric: "558",
  },
  {
    label: "Niger (the)",
    code: "NE",
    iso3: "NER",
    numeric: "562",
  },
  {
    label: "Nigeria",
    code: "NG",
    iso3: "NGA",
    numeric: "566",
  },
  {
    label: "Niue",
    code: "NU",
    iso3: "NIU",
    numeric: "570",
  },
  {
    label: "Norfolk Island",
    code: "NF",
    iso3: "NFK",
    numeric: "574",
  },
  {
    label: "Norway",
    code: "NO",
    iso3: "NOR",
    numeric: "578",
  },
  {
    label: "New Caledonia",
    code: "NC",
    iso3: "NCL",
    numeric: "540",
  },
  {
    label: "New Zealand",
    code: "NZ",
    iso3: "NZL",
    numeric: "554",
  },
  {
    label: "Oman",
    code: "OM",
    iso3: "OMN",
    numeric: "512",
  },
  {
    label: "Uganda",
    code: "UG",
    iso3: "UGA",
    numeric: "800",
  },
  {
    label: "Uzbekistan",
    code: "UZ",
    iso3: "UZB",
    numeric: "860",
  },
  {
    label: "Pakistan",
    code: "PK",
    iso3: "PAK",
    numeric: "586",
  },
  {
    label: "Palau",
    code: "PW",
    iso3: "PLW",
    numeric: "585",
  },
  {
    label: "Palestine, State of",
    code: "PS",
    iso3: "PSE",
    numeric: "275",
  },
  {
    label: "Panama",
    code: "PA",
    iso3: "PAN",
    numeric: "591",
  },
  {
    label: "Papua New Guinea",
    code: "PG",
    iso3: "PNG",
    numeric: "598",
  },
  {
    label: "Paraguay",
    code: "PY",
    iso3: "PRY",
    numeric: "600",
  },
  {
    label: "Netherlands (the)",
    code: "NL",
    iso3: "NLD",
    numeric: "528",
  },
  {
    label: "Peru",
    code: "PE",
    iso3: "PER",
    numeric: "604",
  },
  {
    label: "Philippines (the)",
    code: "PH",
    iso3: "PHL",
    numeric: "608",
  },
  {
    label: "Pitcairn",
    code: "PN",
    iso3: "PCN",
    numeric: "612",
  },
  {
    label: "Poland",
    code: "PL",
    iso3: "POL",
    numeric: "616",
  },
  {
    label: "French Polynesia",
    code: "PF",
    iso3: "PYF",
    numeric: "258",
  },
  {
    label: "Puerto Rico",
    code: "PR",
    iso3: "PRI",
    numeric: "630",
  },
  {
    label: "Portugal",
    code: "PT",
    iso3: "PRT",
    numeric: "620",
  },
  {
    label: "Qatar",
    code: "QA",
    iso3: "QAT",
    numeric: "634",
  },
  {
    label: "Syrian Arab Republic",
    code: "SY",
    iso3: "SYR",
    numeric: "760",
  },
  {
    label: "Central African Republic (the)",
    code: "CF",
    iso3: "CAF",
    numeric: "140",
  },
  {
    label: "Réunion",
    code: "RE",
    iso3: "REU",
    numeric: "638",
  },
  {
    label: "Romania",
    code: "RO",
    iso3: "ROU",
    numeric: "642",
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    code: "GB",
    iso3: "GBR",
    numeric: "826",
  },
  {
    label: "Russian Federation (the)",
    code: "RU",
    iso3: "RUS",
    numeric: "643",
  },
  {
    label: "Rwanda",
    code: "RW",
    iso3: "RWA",
    numeric: "646",
  },
  {
    label: "Western Sahara*",
    code: "EH",
    iso3: "ESH",
    numeric: "732",
  },
  {
    label: "Saint Barthélemy",
    code: "BL",
    iso3: "BLM",
    numeric: "652",
  },
  {
    label: "Saint Kitts and Nevis",
    code: "KN",
    iso3: "KNA",
    numeric: "659",
  },
  {
    label: "San Marino",
    code: "SM",
    iso3: "SMR",
    numeric: "674",
  },
  {
    label: "Saint Martin (French part)",
    code: "MF",
    iso3: "MAF",
    numeric: "663",
  },
  {
    label: "Sint Maarten (Dutch part)",
    code: "SX",
    iso3: "SXM",
    numeric: "534",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "PM",
    iso3: "SPM",
    numeric: "666",
  },
  {
    label: "Holy See (the)",
    code: "VA",
    iso3: "VAT",
    numeric: "336",
  },
  {
    label: "Saint Vincent and the Grenadines",
    code: "VC",
    iso3: "VCT",
    numeric: "670",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    iso3: "SHN",
    numeric: "654",
  },
  {
    label: "Saint Lucia",
    code: "LC",
    iso3: "LCA",
    numeric: "662",
  },
  {
    label: "Solomon Islands",
    code: "SB",
    iso3: "SLB",
    numeric: "090",
  },
  {
    label: "Samoa",
    code: "WS",
    iso3: "WSM",
    numeric: "882",
  },
  {
    label: "American Samoa",
    code: "AS",
    iso3: "ASM",
    numeric: "016",
  },
  {
    label: "Sao Tome and Principe",
    code: "ST",
    iso3: "STP",
    numeric: "678",
  },
  {
    label: "Senegal",
    code: "SN",
    iso3: "SEN",
    numeric: "686",
  },
  {
    label: "Serbia",
    code: "RS",
    iso3: "SRB",
    numeric: "688",
  },
  {
    label: "Seychelles",
    code: "SC",
    iso3: "SYC",
    numeric: "690",
  },
  {
    label: "Sierra Leone",
    code: "SL",
    iso3: "SLE",
    numeric: "694",
  },
  {
    label: "Singapore",
    code: "SG",
    iso3: "SGP",
    numeric: "702",
  },
  {
    label: "Slovakia",
    code: "SK",
    iso3: "SVK",
    numeric: "703",
  },
  {
    label: "Slovenia",
    code: "SI",
    iso3: "SVN",
    numeric: "705",
  },
  {
    label: "Somalia",
    code: "SO",
    iso3: "SOM",
    numeric: "706",
  },
  {
    label: "Sudan (the)",
    code: "SD",
    iso3: "SDN",
    numeric: "729",
  },
  {
    label: "South Sudan",
    code: "SS",
    iso3: "SSD",
    numeric: "728",
  },
  {
    label: "Sri Lanka",
    code: "LK",
    iso3: "LKA",
    numeric: "144",
  },
  {
    label: "Sweden",
    code: "SE",
    iso3: "SWE",
    numeric: "752",
  },
  {
    label: "Switzerland",
    code: "CH",
    iso3: "CHE",
    numeric: "756",
  },
  {
    label: "Surilabel",
    code: "SR",
    iso3: "SUR",
    numeric: "740",
  },
  {
    label: "Svalbard and Jan Mayen",
    code: "SJ",
    iso3: "SJM",
    numeric: "744",
  },
  {
    label: "Swaziland",
    code: "SZ",
    iso3: "SWZ",
    numeric: "748",
  },
  {
    label: "Tajikistan",
    code: "TJ",
    iso3: "TJK",
    numeric: "762",
  },
  {
    label: "Taiwan (Province of China)",
    code: "TW",
    iso3: "TWN",
    numeric: "158",
  },
  {
    label: "Tanzania, United Republic of",
    code: "TZ",
    iso3: "TZS",
    numeric: "834",
  },
  {
    label: "Chad",
    code: "TD",
    iso3: "TCD",
    numeric: "148",
  },
  {
    label: "Czechia",
    code: "CZ",
    iso3: "CZE",
    numeric: "203",
  },
  {
    label: "French Southern Territories (the)",
    code: "TF",
    iso3: "ATF",
    numeric: "260",
  },
  {
    label: "Thailand",
    code: "TH",
    iso3: "THA",
    numeric: "764",
  },
  {
    label: "Timor-Leste",
    code: "TL",
    iso3: "TLS",
    numeric: "626",
  },
  {
    label: "Togo",
    code: "TG",
    iso3: "TGO",
    numeric: "768",
  },
  {
    label: "Tokelau",
    code: "TK",
    iso3: "TKL",
    numeric: "772",
  },
  {
    label: "Tonga",
    code: "TO",
    iso3: "TON",
    numeric: "776",
  },
  {
    label: "Trinidad and Tobago",
    code: "TT",
    iso3: "TTO",
    numeric: "780",
  },
  {
    label: "Tunisia",
    code: "TN",
    iso3: "TUN",
    numeric: "788",
  },
  {
    label: "Turkmenistan",
    code: "TM",
    iso3: "TKM",
    numeric: "795",
  },
  {
    label: "Turks and Caicos Islands (the)",
    code: "TC",
    iso3: "TCA",
    numeric: "796",
  },
  {
    label: "Turkey",
    code: "TR",
    iso3: "TUR",
    numeric: "792",
  },
  {
    label: "Tuvalu",
    code: "TV",
    iso3: "TUV",
    numeric: "798",
  },
  {
    label: "Ukraine",
    code: "UA",
    iso3: "UKR",
    numeric: "804",
  },
  {
    label: "Uruguay",
    code: "UY",
    iso3: "URY",
    numeric: "858",
  },
  {
    label: "Vanuatu",
    code: "VU",
    iso3: "VUT",
    numeric: "548",
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    code: "VE",
    iso3: "VEN",
    numeric: "862",
  },
  {
    label: "Virgin Islands (British)",
    code: "VG",
    iso3: "VGB",
    numeric: "092",
  },
  {
    label: "Virgin Islands (U.S.)",
    code: "VI",
    iso3: "VIR",
    numeric: "850",
  },
  {
    label: "Viet Nam",
    code: "VN",
    iso3: "VNM",
    numeric: "704",
  },
  {
    label: "Wallis and Futuna",
    code: "WF",
    iso3: "WLF",
    numeric: "876",
  },
  {
    label: "Yemen",
    code: "YE",
    iso3: "YEM",
    numeric: "887",
  },
  {
    label: "Zambia",
    code: "ZM",
    iso3: "ZMB",
    numeric: "894",
  },
  {
    label: "Zimbabwe",
    code: "ZW",
    iso3: "ZWE",
    numeric: "716",
  },
];
