import { useEffect, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate } from "react-router-dom";
import {
  addTraditionalCampaignReport,
  addTraditionalCampaignReportByCSV,
  editTraditionalCampaignReport,
  getSingleTraditionalCampaignReport,
} from "../../controllers/traditionalCampaignReportController";
import { useGetParams } from "../../utils/getParams";
import Loader from "../../components/loader";
import { BsFiletypeCsv } from "react-icons/bs";
import Back from "../../components/back";
import moment from "moment";

const EditTraditionalCampaignReport = () => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const params = useGetParams();
  const [report, setreport] = useState(null);
  const [selectedCSV, setSelectedCSV] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    getSingleTraditionalCampaignReport(params.uuid).then((res) => {
      setreport(res.data.body);
      setLoading(false);
    });
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedCSV(file);
    } else {
      toast.error("Please upload a valid CSV file.");
    }
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="space-y-2">
        <h1 className="text-2xl 2xl:text-xl font-bold">Edit Report</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Enter report details below
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const payload = {
            count: e.target.count.value,
            AVE: e.target.AVE.value,
            impressions: e.target.impressions.value,
            clicks: e.target.clicks.value,
            spent: e.target.spent.value,
            videoViews: e.target.videoViews.value,
            allClicks: e.target.allClicks.value,
            engagements: e.target.engagements.value,
            mention: e.target.mention.value,
            recordedAt: e.target.recordedAt.value,
          };
          setUploading(true);
          editTraditionalCampaignReport(params.uuid, payload)
            .then(() => {
              toast.success("Changed successfully");
              navigate(-1);
            })
            .catch(() => {})
            .finally(() => setUploading(false));
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-2 w-6/12 px-8"
      >
        <div>
          <h1 className="text-lg font-bold">Edit report report</h1>
          <p className="text-muted text-sm">All fields are required</p>
          <div className="my-6 mb-8 grid grid-cols-2 gap-4">
            <TextForm
              name="count"
              required={true}
              defaultValue={report.count}
              label="Count"
              inputType="number"
            />
            <TextForm
              name="AVE"
              required={true}
              defaultValue={report.AVE}
              label="AVE"
              inputType="number"
            />
            <TextForm
              name="impressions"
              defaultValue={report.impressions}
              label="Impressions"
              required={true}
              inputType="number"
            />
            <TextForm
              name="clicks"
              defaultValue={report.clicks}
              required={true}
              label="Clicks"
              inputType="number"
            />
            <TextForm
              name="allClicks"
              defaultValue={report.allClicks}
              label="All Clicks"
              required={true}
              inputType="number"
            />
            <TextForm
              name="spent"
              required={true}
              defaultValue={report.spent}
              label="Spent"
              inputType="number"
            />

            <TextForm
              name="engagements"
              defaultValue={report.engagements}
              label="Engagements"
              required={true}
              inputType="number"
            />
            <TextForm
              name="videoViews"
              label="Video Views"
              defaultValue={report.videoViews}
              required={true}
              inputType="number"
            />
            <TextForm
              name="recordedAt"
              defaultValue={moment(report.recordedAt).format("yyy-MM-DD")}
              label="Recorded At"
              required={true}
              inputType="date"
            />

            <TextForm
              required={true}
              name="mention"
              defaultValue={report.mention}
              label="Mention"
            />
          </div>
        </div>

        <SubmitButton loading={uploading} text="Save Changes" />
      </form>
    </div>
  );
};

export default EditTraditionalCampaignReport;
