const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addTraditionalCampaignChannel = async (data) => {
  return await app.post("/traditional-campaign-channels", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const editTraditionalCampaignChannel = async (uuid, data) => {
  return await app.patch(`/traditional-campaign-channels/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteTraditionalCampaignChannel = async (uuid) => {
  return await app.delete(`/traditional-campaign-channels/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
