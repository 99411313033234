import { useEffect, useRef, useState } from "react";
import { getPublishers, getUsers } from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsSignpost2 } from "react-icons/bs";
import { LiaBuysellads } from "react-icons/lia";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import { ImEarth } from "react-icons/im";
import { GrStatusPlaceholder } from "react-icons/gr";
import Back from "../../components/back";
import { getParams, useGetParams } from "../../utils/getParams";
import { getWebsite, getWebsites } from "../../controllers/websitesController";
import moment from "moment";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileImage,
  AiOutlineGlobal,
} from "react-icons/ai";
import {
  deleteZone,
  getInvocationCode,
  getZones,
} from "../../controllers/zonesController";
import { IoCodeSlash } from "react-icons/io5";
import ModalRight from "../../components/modalRight";
import toast from "react-hot-toast";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SubmitButton from "../../components/submitButton";

const WebsitesPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const params = useGetParams();

  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [invocationCode, setInvocationCode] = useState(null);
  const [website, setWebsite] = useState(null);

  const dropdownRef = useRef(null);

  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `${params.uuid}/?limit=${limit}&page=${page}&keyword=${keyword}`;
    getZones(path).then((response) => {
      setWebsite(response.data.body.website);
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <h1 className="text-2xl 2xl:text-xl font-bold">Website Zones</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage {website.name} zones
          </p>
        </div>
        <button
          onClick={() => {
            navigate(`/add-zone/?uuid=${params.uuid}`);
          }}
          className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
        >
          Add Zone
        </button>
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">Zones ({count})</h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <ModalRight
          showModal={showModal}
          content={
            <div className="space-y-1">
              <h1 className="font-bold text-xl mt-3 ">Invocation Code</h1>
              <p className="text-muted text-sm">
                Copy this code and paste it on your website zone
              </p>
              {/* <code lang="js">{invocationCode}</code> */}
              {invocationCode == null ? (
                <Loader />
              ) : (
                <div>
                  <div className="mt-3">
                    <SyntaxHighlighter
                      wrapLongLines={true}
                      language="javascript"
                      style={docco}
                    >
                      {invocationCode}
                    </SyntaxHighlighter>
                  </div>
                  <div
                    onClick={() => {
                      navigator.clipboard
                        .writeText(invocationCode)
                        .then((res) => {
                          toast.success("Copied successfully");
                        });
                    }}
                    className="mt-4"
                  >
                    <SubmitButton text={"Copy Code"} />
                  </div>
                </div>
              )}
            </div>
          }
          setShowModal={() => {
            setShowModal(!showModal);
          }}
        />
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Zone</th>
                <th className="">Width</th>
                <th className="">Height</th>

                <th className="">Type</th>
                <th className="">Status</th>
                <th className=""></th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr key={item.uuid}>
                    <td className="text-start py-4 ">
                      {moment(item.createdAt).format("yyy, MMM DD")}
                    </td>
                    <td className="">{item.name}</td>
                    <td className="">{item.width}px</td>
                    <td className="">{item.height}px</td>
                    <td className="">{item.type}</td>
                    <td className={`text-start py-4  `}>
                      <button
                        className={` px-3 text-sm py-1 font-medium rounded-full ${
                          item.isApproved
                            ? " bg-primary bg-opacity-10 text-primary"
                            : "bg-dark bg-opacity-10 text-muted"
                        }`}
                      >
                        {item.isApproved ? "Approved" : "In Review"}
                      </button>
                    </td>
                    <td className="">
                      <div className="relative">
                        <HiDotsVertical
                          className=" cursor-pointer"
                          onClick={() => {
                            if (showOptions == false) {
                              setSelectedItem(item);
                              setShowOptions(!showOptions);
                            }
                          }}
                        />
                        {showOptions == true &&
                          selectedItem.uuid == item.uuid && (
                            <div ref={dropdownRef} className="popup-style">
                              {item.isApproved && (
                                <SidebarItem
                                  isSidebar={false}
                                  icon={<IoCodeSlash />}
                                  path={"/"}
                                  onClick={() => {
                                    setShowModal(true);
                                    setShowOptions(false);
                                    setSelectedItem(item);
                                    setInvocationCode(null);
                                    getInvocationCode(item.uuid).then((res) => {
                                      console.log(data);
                                      setInvocationCode(res.data.body);
                                    });
                                  }}
                                  title={"Invocation Code"}
                                />
                              )}
                              <SidebarItem
                                isSidebar={false}
                                icon={<AiOutlineGlobal />}
                                path={"/"}
                                onClick={() => {
                                  window.open(item.pageUrl, "_blank");
                                }}
                                title={"Visit zone's page"}
                              />
                              <SidebarItem
                                isSidebar={false}
                                icon={<AiOutlineFileImage />}
                                path={`/zone-banners/?uuid=${item.uuid}`}
                                title={"Linked Banners"}
                              />
                              <SidebarItem
                                isSidebar={false}
                                icon={<AiOutlineDelete />}
                                onClick={() => {
                                  toast.success("Deleted successfully");
                                  deleteZone(item.uuid).then((data) => {
                                    getData();
                                    setShowOptions(false);
                                    setSelectedItem(null);
                                  });
                                }}
                                title={"Delete zone"}
                              />
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default WebsitesPage;
