import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetParams } from "../../utils/getParams";
import { UserContext } from "../../layouts/dashboardLayout";
import Back from "../../components/back";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import {
  addTiktokCampaign,
  editTiktokCampaign,
  getTiktokCampaign,
} from "../../controllers/tiktokCampaignController";
import toast from "react-hot-toast";
import { showError } from "../../utils/showError";
import Loader from "../../components/loader";
import moment from "moment";

const EditTiktokCampaign = () => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const params = useGetParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getTiktokCampaign(params.uuid).then((res) => {
      setData(res.data.body);
      setLoading(false);
      console.log(res.data.body);
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div>
        <Back />

        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <h1 className="text-2xl 2xl:text-xl font-bold">{data.name}</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
              Edit Campaigns Below
            </p>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const payload = {
              name: e.target.name.value,
              budget: e.target.budget.value,
              spent: e.target.spent.value,
              startDate: e.target.startDate.value,
              endDate: e.target.endDate.value,
            };
            setUploading(true);
            editTiktokCampaign(params.uuid, payload)
              .then((data) => {
                toast.success("Saved successfully");
                navigate(-1);
                setUploading(false);
              })
              .catch((err) => {
                setUploading(false);
                showError(err);
              });
          }}
          className="bg-white dark:bg-darkLight py-12 rounded-xl mt-8 w-6/12 px-8"
        >
          <div className="space-y-1">
            <h1 className="text-lg font-bold">Edit campaign</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
              All fields are required
            </p>
          </div>
          <div className="space-y-4 my-6 mb-8">
            <TextForm
              placeholder={"Enter campaign name"}
              name={"name"}
              defaultValue={data.name}
              label={"Campaign name"}
            />
            <TextForm
              placeholder={"Enter your budget"}
              name={"budget"}
              defaultValue={data.budget}
              inputType={"number"}
              label={"Budget"}
            />
            <TextForm
              placeholder={"Enter spent amount"}
              name={"spent"}
              defaultValue={data.spent}
              inputType={"number"}
              label={"Spent"}
            />
            <TextForm
              placeholder={"Enter  Address"}
              name={"startDate"}
              defaultValue={moment(data.startDate).format("yyy-MM-DD")}
              inputType={"date"}
              label={"Start Date"}
            />
            <TextForm
              placeholder={"Enter  Address"}
              name={"endDate"}
              defaultValue={moment(data.endDate).format("yyy-MM-DD")}
              inputType={"date"}
              label={"End Date"}
            />
          </div>
          <SubmitButton loading={uploading} text={`Save Changes`} />
        </form>
      </div>
    </div>
  );
};

export default EditTiktokCampaign;
