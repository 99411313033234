import { useNavigate } from "react-router-dom";
import Back from "../../components/back";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../layouts/dashboardLayout";
import { useGetParams } from "../../utils/getParams";
import Pagination from "../../components/pagination";
import {
  deleteTiktokCampaign,
  getTiktokCampaign,
  getTiktokCampaigns,
} from "../../controllers/tiktokCampaignController";
import moment from "moment";
import { getTraditionalCampaigns } from "../../controllers/traditionalCampaignController";
import { HiDotsVertical } from "react-icons/hi";
import { MdInsertChartOutlined } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import SidebarItem from "../../components/sidebarItem";
import { closePopupMenu } from "../../utils/closePopupMenu";
import toast from "react-hot-toast";
import Loader from "../../components/loader";
import { AiOutlineEdit } from "react-icons/ai";

const TiktokCampaigns = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const params = useGetParams();
  const { user } = useContext(UserContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `${params.uuid}/?limit=${limit}&page=${page}&keyword=${keyword}`;

    getTiktokCampaigns(path).then((response) => {
      console.log(response);
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div>
        <Back />
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <h1 className="text-2xl 2xl:text-xl font-bold">Tiktok Campaigns</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
              Manage Tiktok Campaigns Below
            </p>
          </div>
          {user.role == "admin" && (
            <button
              onClick={() => {
                navigate(`/add-tiktok-campaign/?uuid=${params.uuid}`);
              }}
              className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
            >
              Add Campaign
            </button>
          )}
        </div>
        <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
          <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
            <h1 className="font-bold text-lg">Campaigns ({data.length})</h1>
            <input
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              className="search-style"
              placeholder="Search here"
            />
          </div>
          <div className="px-6 py-5">
            <table className="">
              <thead>
                <tr>
                  <th className="">Created At</th>

                  <th className="">Name</th>
                  <th className="">Budget</th>
                  <th className="">Spent</th>
                  <th className="">Activate Time</th>
                  <th className="">Expire Time</th>
                </tr>
              </thead>
              <tbody className="mt-2">
                {data.map((item) => {
                  return (
                    <tr key={item.uuid}>
                      <td className="">
                        {moment(item.startDate).format("yyy, MMM DD")}
                      </td>
                      <td className="">{item.name}</td>
                      <td className="">{`$${item.budget.toLocaleString()}`}</td>
                      <td className="">{`$${item.spent.toLocaleString()}`}</td>
                      <td className="">
                        {moment(item.startDate).format("yyy, MMM DD")}
                      </td>
                      <td className="">
                        {moment(item.endDate).format("yyy, MMM DD")}
                      </td>
                      <td className="">{item.media}</td>
                      <td className="">
                        <div className="relative">
                          <HiDotsVertical
                            className=" cursor-pointer"
                            onClick={() => {
                              if (showOptions == false) {
                                setSelectedItem(item);
                                setShowOptions(!showOptions);
                              }
                            }}
                          />
                          {showOptions == true &&
                            selectedItem.uuid == item.uuid && (
                              <div ref={dropdownRef} className="popup-style">
                                <SidebarItem
                                  isSidebar={false}
                                  icon={<MdInsertChartOutlined />}
                                  path={`/tiktok-campaign-report/?uuid=${item.uuid}&name=${item.name}`}
                                  title={"Campaign Report"}
                                />
                                {user.role == "admin" && (
                                  <div>
                                    <SidebarItem
                                      isSidebar={false}
                                      icon={<AiOutlineEdit />}
                                      path={`/edit-tiktok-campaign/?uuid=${item.uuid}`}
                                      title={"Edit Campaign"}
                                    />
                                    <SidebarItem
                                      isSidebar={false}
                                      icon={<BsTrash />}
                                      onClick={() => {
                                        toast.success("Deleted Successfully");
                                        deleteTiktokCampaign(item.uuid).then(
                                          (res) => {
                                            setShowOptions(false);
                                            setSelectedItem(false);
                                            getData();
                                          }
                                        );
                                      }}
                                      title={"Delete Campaign"}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              limit={limit}
              count={count}
              setPage={setPage}
              page={page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TiktokCampaigns;
