import { useEffect, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate } from "react-router-dom";
import {
  addTraditionalCampaignReport,
  addTraditionalCampaignReportByCSV,
} from "../../controllers/traditionalCampaignReportController";
import { useGetParams } from "../../utils/getParams";
import { getTraditionalCampaign } from "../../controllers/traditionalCampaignController";
import Loader from "../../components/loader";
import { BsFiletypeCsv } from "react-icons/bs";
import Back from "../../components/back";

const AddTraditionalCampaignReport = () => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const params = useGetParams();
  const [campaign, setCampaign] = useState(null);
  const [selectedCSV, setSelectedCSV] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    getTraditionalCampaign(params.uuid).then((res) => {
      setCampaign(res.data.body);
      setLoading(false);
    });
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedCSV(file);
    } else {
      toast.error("Please upload a valid CSV file.");
    }
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="space-y-2">
        <h1 className="text-2xl 2xl:text-xl font-bold">Add Report</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Enter report details below
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (selectedIndex === 0) {
            const payload = {
              count: e.target.count.value,
              AVE: e.target.AVE.value,
              impressions: e.target.impressions.value,
              clicks: e.target.clicks.value,
              spent: e.target.spent.value,
              videoViews: e.target.videoViews.value,
              allClicks: e.target.allClicks.value,
              angagements: e.target.angagements.value,
              mention: e.target.mention.value,
              recordedAt: e.target.recordedAt.value,
              traditional_campaign_channel_uuid:
                e.target.traditional_campaign_channel_uuid.value,
            };
            setUploading(true);
            addTraditionalCampaignReport(payload)
              .then(() => {
                toast.success("Added successfully");
                navigate(-1);
              })
              .catch(() => {})
              .finally(() => setUploading(false));
          } else {
            setUploading(true);

            const formData = new FormData();
            formData.append("file", selectedCSV);
            formData.append("campaign_uuid", params.uuid);

            addTraditionalCampaignReportByCSV(formData)
              .then(() => {
                toast.success("Uploaded successfully");
                navigate(-1);
                setUploading(false);
              })
              .catch((e) => {
                setUploading(false);
                toast.error(e.response.data.message);
              });
          }
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-8 w-6/12 px-8"
      >
        <div className="flex space-x-4 mb-2">
          {["Single Upload", "Upload using CSV"].map((item, index) => (
            <div
              onClick={() => setSelectedIndex(index)}
              className={`${
                selectedIndex === index
                  ? "text-primary border-primary"
                  : "text-muted border-transparent text-opacity-60"
              } border-b-4 pb-2 cursor-pointer`}
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
        {selectedIndex === 0 ? (
          <div>
            <h1 className="text-lg font-bold">Add new report</h1>
            <div className="my-6 mb-8 grid grid-cols-2 gap-4">
              <TextForm
                name="count"
                required={true}
                label="Count"
                inputType="number"
              />
              <TextForm
                name="AVE"
                required={true}
                label="AVE"
                inputType="number"
              />
              <TextForm
                name="impressions"
                label="Impressions"
                required={true}
                inputType="number"
              />
              <TextForm
                name="clicks"
                required={true}
                label="Clicks"
                inputType="number"
              />
              <TextForm
                name="allClicks"
                label="All Clicks"
                required={true}
                inputType="number"
              />
              <TextForm
                name="spent"
                required={true}
                label="Spent"
                inputType="number"
              />

              <TextForm
                name="angagements"
                label="Angagements"
                required={true}
                inputType="number"
              />
              <TextForm
                name="videoViews"
                label="Video Views"
                required={true}
                inputType="number"
              />
              <TextForm
                name="recordedAt"
                label="Recorded At"
                required={true}
                inputType="date"
              />
              <SelectForm
                required={true}
                options={campaign.TraditionalCampaignChannels.map(
                  (item) => item.Channel.name
                )}
                values={campaign.TraditionalCampaignChannels.map(
                  (item) => item.uuid
                )}
                name="traditional_campaign_channel_uuid"
                label="Select channel"
              />
              <TextForm required={true} name="mention" label="Mention" />
            </div>
          </div>
        ) : (
          <label
            for="csv"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={`${
              isDragging ? "border-primary bg-blue-100" : "border-dashed"
            } flex flex-col items-center py-12 border-2 cursor-pointer`}
          >
            <BsFiletypeCsv className="text-4xl mb-3" />
            <p className="text-muted text-sm">
              {selectedCSV
                ? selectedCSV.name
                : "Drag and drop a CSV file or click to select"}
            </p>
            <input
              type="file"
              accept=".csv"
              id="csv"
              required
              onChange={(e) => setSelectedCSV(e.target.files[0])}
              className="sr-only"
            />
          </label>
        )}
        <SubmitButton loading={uploading} text="Add report" />
      </form>
    </div>
  );
};

export default AddTraditionalCampaignReport;
