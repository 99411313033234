import { AnimatePresence, motion } from "framer-motion";

const ModalBottom = ({ showModal, setShowModal, content, expanded }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <div>
          <div
            className="fixed inset-0 z-40  flex flex-col bg-black bg-opacity-10"
            onClick={() => setShowModal(false)}
          >
            <motion.div
              initial={{ y: 600 }}
              animate={{ y: 0 }}
              exit={{ y: 600 }}
              transition={{ ease: "linear" }}
              className="mt-auto w-full min-h-[60vh] overflow-y-auto max-h-[70vh] bg-white px-8 py-3"
              onClick={(e) => e.stopPropagation()} // Prevents close on content click
            >
              <div className="w-20 2xl:w-12 h-2 bg-background mt-2 mx-auto rounded-xl"></div>
              {content}
            </motion.div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModalBottom;
