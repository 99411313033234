const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addContract = async (data) => {
  return await app.post("/contracts/", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getContracts = async (path) => {
  return await app.get(`/contracts/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getContract = async (uuid) => {
  return await app.get(`/contracts/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editContract = async (uuid, data) => {
  return await app.patch(`/contracts/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteContract = async (uuid) => {
  return await app.delete(`/contracts/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
