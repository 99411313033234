import { useContext, useEffect, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import { showError } from "../../utils/showError";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { registerUser } from "../../controllers/userController";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
  AiOutlinePlus,
} from "react-icons/ai";
import Back from "../../components/back";
import { addWebsiteCategory } from "../../controllers/websiteCategoriesController";
import { addChannel, getChannels } from "../../controllers/channelController";
import ModalRight from "../../components/modalRight";
import Switch from "../../components/switch";
import { useGetParams } from "../../utils/getParams";
import { addTraditionalCampaign } from "../../controllers/traditionalCampaignController";
import { FaEarthAmericas, FaWebAwesome } from "react-icons/fa6";

const AddTraditionalCampaign = () => {
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [addedBlogs, setAddedBlogs] = useState([]);
  const [showblogsModal, setshowblogsModal] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [keyword, setKeyword] = useState("");
  const params = useGetParams();
  useEffect(() => {
    getChannels(`?limit=${100}&page=${1}&keyword=${""}`).then((res) => {
      setChannels(res.data.body.rows);
    });
  }, []);
  return (
    <div>
      <Back />
      <div className="space-y-2">
        <h1 className="text-2xl 2xl:text-xl font-bold">New Campaign</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Enter campaign details below
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (selectedChannels.length < 1) {
            toast.error("Please Select channels");
          } else {
            const payload = {
              name: e.target.name.value,
              type: e.target.type.value,
              budget: e.target.budget.value,
              activateTime: e.target.activateTime.value,
              expireTime: e.target.expireTime.value,
              user_uuid: params.uuid,
              stories: addedBlogs,
              channels: selectedChannels.map((item) => item.uuid),
            };
            setUploading(true);
            addTraditionalCampaign(payload)
              .then((res) => {
                toast.success("added successfully");
                navigate(-1);
                setUploading(false);
              })
              .catch((err) => {
                setUploading(false);
                showError(err);
              });
          }
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-4 w-6/12 px-8"
      >
        <div className="space-y-1">
          <h1 className="text-lg font-bold">Add new campaign</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            All fields are required
          </p>
        </div>
        <div className="space-y-4 my-6 mb-2">
          <SelectForm
            name={"type"}
            options={["PR", "Campaign"]}
            label={"Type"}
          />
          <TextForm
            placeholder={"Enter campaign name"}
            name={"name"}
            required
            label={"Campaign Name"}
          />
          <TextForm
            placeholder={"Enter your budget"}
            name={"budget"}
            inputType={"number"}
            required
            label={"Budget"}
          />
          <TextForm
            placeholder={"Enter channel name"}
            name={"activateTime"}
            required
            inputType={"date"}
            label={"Activate Time"}
          />
          <TextForm
            placeholder={"Enter channel name"}
            name={"expireTime"}
            required
            inputType={"date"}
            label={"Expire Time"}
          />
          <div>
            <h1 className="text-muted">
              Channels that this campaign will run ?
            </h1>
            <div
              onClick={() => {
                setShowChannels(true);
              }}
              className="flex space-x-2 cursor-pointer items-center text-sm text-primary "
            >
              <AiOutlinePlus />
              <p>Select channels</p>
            </div>
            {selectedChannels.length > 0 && (
              <div
                onClick={() => {
                  setShowChannels(true);
                }}
                className="bg-background cursor-pointer px-4 py-2 flex items-center justify-between rounded-xl mt-3"
              >
                <h1>Selected channels ({selectedChannels.length})</h1>
                <AiOutlineArrowRight />
              </div>
            )}
          </div>
          <ModalRight
            content={
              <div>
                <h1 className="text-2xl font-bold mb-2 mt-3">Channels</h1>
                <input
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  className="search-style w-full"
                  placeholder="Search channels here"
                />
                <div className="space-y-2 mt-4">
                  {channels
                    .filter((e) =>
                      e.name.toLowerCase().includes(keyword.toLowerCase())
                    )
                    .map((item) => {
                      return (
                        <div className="flex justify-between text-sm text-muted">
                          <div>
                            <h1 className="text-base  text-dark">
                              {item.name}
                            </h1>
                            <p>{item.media}</p>
                          </div>
                          <Switch
                            onClick={() => {
                              if (
                                selectedChannels.filter(
                                  (e) => e.name == item.name
                                ).length > 0
                              ) {
                                const newList = selectedChannels.filter(
                                  (e) => e.name != item.name
                                );
                                setSelectedChannels(newList);
                              } else {
                                setSelectedChannels([
                                  ...selectedChannels,
                                  item,
                                ]);
                              }
                            }}
                            isActive={
                              selectedChannels.filter(
                                (e) => e.name == item.name
                              ).length > 0
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            }
            showModal={showChannels}
            setShowModal={() => {
              setShowChannels(false);
            }}
          />
          <div className="flex justify-between">
            <h1 className="text-muted">Stories</h1>
            <div
              onClick={() => {
                setshowblogsModal(true);
              }}
              className="flex space-x-2 cursor-pointer items-center text-sm text-primary "
            >
              <AiOutlinePlus />
              <p>Add Story</p>
            </div>
          </div>
          <div className="space-y-1 grid grid-cols-2 gap-4">
            {Object.keys(addedBlogs).map((item, index) => {
              return (
                <div key={item.image}>
                  <div className="flex justify-end">
                    <AiOutlineClose
                      onClick={() => {
                        const updatedBlogs = { ...addedBlogs };
                        delete updatedBlogs[index];
                        setAddedBlogs(updatedBlogs);
                      }}
                      className="text-muted cursor-pointer"
                    />
                  </div>
                  <a
                    href={Object.values(addedBlogs)[index].link}
                    target="_blank"
                    className="line-clamp-1 text-start w-11/12 text-muted hover:text-primary transition-all duration-200"
                  >
                    <img
                      alt={Object.values(addedBlogs)[index].link}
                      className="rounded-lg h-52 w-full object-cover"
                      src={Object.values(addedBlogs)[index].image}
                    />

                    <h1 className="mt-2 text-sm">
                      {Object.values(addedBlogs)[index].link}
                    </h1>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <SubmitButton loading={uploading} text={`Add campaign`} />
      </form>
      <ModalRight
        showModal={showblogsModal}
        setShowModal={() => {
          setshowblogsModal(false);
        }}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const link = e.target.link.value;
              const image = e.target.image.value;
              let payload = addedBlogs;
              payload[addedBlogs.length] = { link, image };
              setAddedBlogs(payload);
              toast.success("added successfully");
              setshowblogsModal(false);
            }}
            className=""
          >
            <h1 className="font-bold ">Add story</h1>
            <p className="text-sm text-muted mb-6">
              Story that this campaigns runs
            </p>
            <div className="space-y-2">
              {/* <TextForm label={"Upload image"} inputType={"file"} /> */}
              <TextForm
                label={"Image Link"}
                name={"image"}
                placeholder={"Enter image link"}
              />
              <TextForm
                label={"URL"}
                name={"link"}
                placeholder={"Upload blog link here"}
              />
            </div>
            <SubmitButton text={"Add"} />
          </form>
        }
      />
    </div>
  );
};

export default AddTraditionalCampaign;
