import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetParams } from "../../utils/getParams";
import { UserContext } from "../../layouts/dashboardLayout";
import Back from "../../components/back";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import { addTiktokCampaign } from "../../controllers/tiktokCampaignController";
import toast from "react-hot-toast";
import { showError } from "../../utils/showError";
import { FaPlus } from "react-icons/fa";
import ModalRight from "../../components/modalRight";
import { addTiktokCampaignReport } from "../../controllers/tiktokCampaignReportController";
import { TikTokEmbed } from "react-social-media-embed";
import { AiOutlineClose } from "react-icons/ai";

const AddTiktokCampaignReport = () => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [audienceRegions, setAudienceRegions] = useState({});
  const [campaignVideos, setcampaignVideos] = useState({});
  const [showRightModal, setShowRightModal] = useState(false);
  const [addCampaignVideos, setaddCampaignVideos] = useState(false);
  const params = useGetParams();

  const { user } = useContext(UserContext);
  return (
    <div>
      <div>
        <Back />

        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <h1 className="text-2xl 2xl:text-xl font-bold">Campaign Report</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
              Add Manage Tiktok Campaigns Below
            </p>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const payload = {
              tiktok_campaign_uuid: params.uuid,
              likes: e.target.likes.value,
              comments: e.target.comments.value,
              shares: e.target.shares.value,
              buttonClicks: e.target.buttonClicks.value,
              avarageWatchTime: e.target.avarageWatchTime.value,
              watchedFullVideo: e.target.watchedFullVideo.value,
              profileViews: e.target.profileViews.value,
              newFollowers: e.target.newFollowers.value,
              audienceGenderPercent: {
                Male: e.target.maleGender.value,
                Female: e.target.femaleGender.value,
              },
              audienceAgePercent: {
                "13-17": e.target.firstAge.value,
                "18-24": e.target.secondAge.value,
                "25-34": e.target.thirdAge.value,
                "35-44": e.target.fourthAge.value,
                "45-54": e.target.fifthAge.value,
                "55+": e.target.sixthAge.value,
              },
              audienceRegionPercent: audienceRegions,
              campaignVideos,
            };
            setUploading(true);
            addTiktokCampaignReport(payload)
              .then((data) => {
                toast.success("added successfully");
                navigate(-1);
                setUploading(false);
              })
              .catch((err) => {
                setUploading(false);
                showError(err);
              });
          }}
          className=" w-6/12 "
        >
          <div className=" bg-white dark:bg-darkLight px-8 py-10  rounded-xl mt-8 ">
            <div className="space-y-1">
              <h1 className="text-lg font-bold">Add Tiktok campaign</h1>
              <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                All fields are required
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 my-6 ">
              <TextForm
                placeholder={"Enter likes count"}
                name={"likes"}
                required
                inputType={"number"}
                label={"Likes"}
              />
              <TextForm
                placeholder={"Enter comments count"}
                name={"comments"}
                required
                inputType={"number"}
                label={"Comments"}
              />
              <TextForm
                placeholder={"Enter shares count"}
                name={"shares"}
                required
                inputType={"number"}
                label={"Shares"}
              />
              <TextForm
                placeholder={"Enter button clicks count"}
                name={"buttonClicks"}
                inputType={"number"}
                required
                label={"Button Clicks"}
              />
              <TextForm
                placeholder={"Enter video views"}
                name={"avarageWatchTime"}
                required
                label={"Video Views"}
              />
              <TextForm
                placeholder={"Enter watched full video count"}
                name={"watchedFullVideo"}
                required
                label={"Watched full video (%)"}
              />
              <TextForm
                placeholder={"Enter profile views count"}
                name={"profileViews"}
                inputType={"number"}
                required
                label={"Profile Views"}
              />
              <TextForm
                placeholder={"Enter new followers count"}
                name={"newFollowers"}
                inputType={"number"}
                required
                label={"New Followers"}
              />
            </div>
          </div>
          <div className=" bg-white dark:bg-darkLight px-8 py-10  rounded-xl mt-8 ">
            <div className="flex justify-between">
              <div className="space-y-1">
                <h1 className="text-lg font-bold">Audience Gender</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  Enter adience percent
                </p>
              </div>
            </div>
            <div className=" my-6 gap-4 grid grid-cols-2 ">
              <TextForm
                placeholder={"Enter male audience percent"}
                name={"maleGender"}
                required
                inputType={"number"}
                label={"Male audience percent"}
              />
              <TextForm
                placeholder={"Enter female audience percent"}
                name={"femaleGender"}
                inputType={"number"}
                required
                label={"Female audience percent"}
              />
            </div>
          </div>
          <div className=" bg-white dark:bg-darkLight px-8 py-10  rounded-xl mt-8 ">
            <div className="flex justify-between">
              <div className="space-y-1">
                <h1 className="text-lg font-bold">Audience Age percent</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  Enter adience age percent
                </p>
              </div>
            </div>
            <div className=" my-6 gap-4 grid grid-cols-2">
              <TextForm
                placeholder={"Percent from 13-17"}
                name={"firstAge"}
                required
                inputType={"number"}
                label={"13-17 audience percent"}
              />
              <TextForm
                placeholder={"Percent from 18-24"}
                name={"secondAge"}
                required
                inputType={"number"}
                label={"18-24 audience percent"}
              />
              <TextForm
                placeholder={"Percent from 25-34"}
                name={"thirdAge"}
                required
                inputType={"number"}
                label={"25-34 audience percent"}
              />
              <TextForm
                placeholder={"Percent from 35-44"}
                name={"fourthAge"}
                required
                inputType={"number"}
                label={"35-44 audience percent"}
              />
              <TextForm
                placeholder={"Percent from 45-54"}
                name={"fifthAge"}
                required
                inputType={"number"}
                label={"45-54 audience percent"}
              />
              <TextForm
                placeholder={"Percent from 55+"}
                name={"sixthAge"}
                required
                inputType={"number"}
                label={"55+ audience percent"}
              />
            </div>
          </div>
          <div className=" bg-white dark:bg-darkLight px-8 py-10  rounded-xl mt-8 ">
            <div className="flex justify-between items-center">
              <div className="space-y-1">
                <h1 className="text-lg font-bold">Audience Regions</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  Enter adience regious percent
                </p>
              </div>
              <div
                onClick={() => {
                  setShowRightModal(true);
                }}
              >
                <FaPlus className="text-primary cursor-pointer" />
              </div>
            </div>
            <div className="space-y-2 mt-2">
              {Object.keys(audienceRegions).map((item, index) => {
                return (
                  <div className="bg-background bg-opacity-40 flex justify-between p-3 rounded-lg">
                    <div>
                      <h1>{Object.keys(audienceRegions)[index]}</h1>
                      <p className="text-sm text-muted">
                        {Object.values(audienceRegions)[index]}%
                      </p>
                    </div>
                    <AiOutlineClose
                      className="cursor-pointer text-muted"
                      onClick={() => {
                        const updatedRegions = { ...audienceRegions };
                        delete updatedRegions[item];
                        setAudienceRegions(updatedRegions);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className=" my-6 space-y-3 "></div>
          </div>
          <div className=" bg-white dark:bg-darkLight px-8 py-10  rounded-xl mt-8 ">
            <div className="flex justify-between items-center">
              <div className="space-y-1">
                <h1 className="text-lg font-bold">Campaign Videos</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  Add campaign videos
                </p>
              </div>
              <div
                onClick={() => {
                  setaddCampaignVideos(true);
                }}
              >
                <FaPlus className="text-primary cursor-pointer" />
              </div>
            </div>
            <div className="space-y-2 mt-2">
              {Object.keys(campaignVideos).map((item, index) => {
                return (
                  <div className="bg-background bg-opacity-40 p-3 rounded-lg">
                    <div className="flex justify-end">
                      <AiOutlineClose
                        className="cursor-pointer text-muted"
                        onClick={() => {
                          const updatedVideos = { ...campaignVideos };
                          delete updatedVideos[item];
                          setcampaignVideos(updatedVideos);
                        }}
                      />
                    </div>
                    <TikTokEmbed
                      url={Object.values(campaignVideos)[index]}
                      width={325}
                    />
                  </div>
                );
              })}
            </div>
            <div className=" my-6 space-y-3 "></div>
          </div>
          <SubmitButton loading={uploading} text={"Submit Report"} />
        </form>
      </div>
      <ModalRight
        showModal={showRightModal}
        setShowModal={() => {
          setShowRightModal(false);
        }}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const region = e.target.region.value;
              const percent = e.target.percent.value;
              let newAudienceRegions = audienceRegions;
              newAudienceRegions[region] = percent;
              setAudienceRegions(newAudienceRegions);
              setShowRightModal(false);
            }}
          >
            <h1 className="text-lg font-bold">Region Record</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 mb-4">
              Enter region data
            </p>
            <div className="space-y-3">
              <TextForm
                placeholder={"Enter region name"}
                name={"region"}
                required
                label={"Region"}
              />
              <TextForm
                placeholder={"Enter percent"}
                name={"percent"}
                required
                inputType={"number"}
                label={"Percent"}
              />
              <SubmitButton text={"Add Record"} />
            </div>
          </form>
        }
      />
      <ModalRight
        showModal={addCampaignVideos}
        setShowModal={() => {
          setaddCampaignVideos(false);
        }}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const title = Object.keys(campaignVideos).length + 1;
              const link = e.target.link.value;
              let newVideos = campaignVideos;
              newVideos[title] = link;
              setcampaignVideos(newVideos);
              setaddCampaignVideos(false);
            }}
          >
            <h1 className="text-lg font-bold">Add Video</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 mb-4">
              All fields are required
            </p>
            <div className="space-y-3">
              <TextForm
                placeholder={"Enter video link"}
                name={"link"}
                required
                label={"Video Link"}
              />
              <SubmitButton text={"Add Video"} />
            </div>
          </form>
        }
      />
    </div>
  );
};

export default AddTiktokCampaignReport;
