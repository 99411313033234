import { useContext, useEffect, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import { showError } from "../../utils/showError";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { registerUser } from "../../controllers/userController";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Back from "../../components/back";
import {
  addWebsite,
  editWebsite,
  getWebsite,
} from "../../controllers/websitesController";
import { useGetParams } from "../../utils/getParams";
import { getWebsiteCategories } from "../../controllers/websiteCategoriesController";
import Loader from "../../components/loader";
import { editAgency } from "../../controllers/agencyController";

const EditWebsite = () => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useGetParams();
  const [categories, setCategories] = useState([]);
  const { uuid } = useParams();
  const [data, setData] = useState(null);
  useEffect(() => {
    getWebsite(uuid).then((res) => {
      setData(res.data.body);
      console.log(res.data.body);
      getWebsiteCategories().then((response) => {
        setCategories(response.data.body);
        setLoading(false);
      });
    });
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="space-y-2">
        <h1 className="text-2xl 2xl:text-xl font-bold">Edit details</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Edit details of {data.name}
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const payload = {
            website: e.target.website.value,
            type: e.target.type.value,
            category_uuid: e.target.category.value,
          };
          console.log(payload);
          setUploading(true);
          editWebsite(data.uuid, payload)
            .then((res) => {
              toast.success("Updated successfully");
              navigate(-1);
              setUploading(false);
            })
            .catch((err) => {
              setUploading(false);
              showError(err);
            });
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-8 w-6/12 px-8"
      >
        <div className="space-y-1">
          <h1 className="text-lg font-bold">Add website </h1>
          <p className="text-base text-muted dark:text-white dark:text-opacity-50">
            All fields are required
          </p>
        </div>
        <div className="space-y-4 my-6 mb-8">
          <SelectForm
            placeholder={"Enter website name"}
            name={"category"}
            defaultValue={data.WebsiteCategory.uuid}
            label={"Category"}
            values={categories.map((item) => item.uuid)}
            options={categories.map((item) => item.name)}
          />
          <SelectForm
            placeholder={"Enter website type"}
            name={"type"}
            defaultValue={data.type}
            label={"Type"}
            options={["Mass", "Premium"]}
          />
          <TextForm
            placeholder={"Enter website name"}
            name={"website"}
            defaultValue={data.name}
            label={"Website name"}
          />
        </div>
        <SubmitButton loading={uploading} text={`Save Changes`} />
      </form>
    </div>
  );
};

export default EditWebsite;
