const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addTiktokCampaignReport = async (data) => {
  return await app.post("/tiktok-campaign-reports", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const addTiktokCampaignReportByCSV = async (data) => {
  return await app.post("/tiktok-campaign-reports/csv", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
      "Content-Type": "multpart/form-data",
    },
  });
};
export const getTiktokCampaignReports = async (path) => {
  return await app.get(`/tiktok-campaign-reports/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getTraditionalCampaignChannelReports = async (path) => {
  return await app.get(`/tiktok-campaign-reports/channel/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getTiktokCampaignReport = async (uuid) => {
  return await app.get(`/tiktok-campaign-reports/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editTiktokCampaignReport = async (uuid, data) => {
  return await app.patch(`/tiktok-campaign-reports/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteTiktokCampaignReport = async (uuid) => {
  return await app.delete(`/tiktok-campaign-reports/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
