import { useEffect } from "react";
import {
  Md10Mp,
  MdBatterySaver,
  MdImage,
  MdOndemandVideo,
  MdOutlineAdsClick,
  MdSsidChart,
  MdVideoLibrary,
  MdWeb,
} from "react-icons/md";
import {
  FaFacebook,
  FaInstagram,
  FaInstagramSquare,
  FaLinkedin,
  FaRegImage,
  FaTwitter,
  FaWeebly,
} from "react-icons/fa";
import { PiImageBrokenThin } from "react-icons/pi";
import { BsFiletypeHtml } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const DemoPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "//revive.defendertz.com/delivery/asyncjs.php";
    script.async = true;
    // Append the script to the document
    document.body.appendChild(script);

    // Clean up the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className=" font-medium">
      <div className="border-b-2 border-muted border-opacity-5">
        <div className="flex w-11/12 mx-auto 2xl:w-11/12 py-3 items-center justify-between ">
          <div className="flex space-x-4 items-center ">
            <h1 className="text-2xl font-bold">Kwanza</h1>

            {[
              { title: "Image Banners", path: "#image" },
              { title: "HTML Banners", path: "#html" },
              { title: "HTML Video Banners", path: "#html" },
            ].map((item) => {
              return (
                <a
                  className="text-sm text-muted font-medium mt-1 hover:text-primary transition-all duration-200"
                  href={item.path}
                >
                  {item.title}
                </a>
              );
            })}
          </div>
          <div className="flex space-x-4 items-center">
            <p
              onClick={() => {
                navigate("/login");
              }}
              className="text-muted text-sm cursor-pointer font-semibold hover:text-primary transition-all duration-200"
            >
              Login
            </p>
            <button
              onClick={() => {
                navigate("/register");
              }}
              className=" hover:scale-105 transition-all duration-200 bg-primary text-white font-semibold text-sm py-3 rounded-full px-8"
            >
              Register
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center w-9/12 2xl:w-7/12 mx-auto mt-24 space-x-24">
        <div className="w-6/12 pr-6">
          <h1 className="text-4xl 2xl:text-5xl font-bold leading-[45px] 2xl:leading-[60px] pr-16">
            Showcasing Ads from our demo SSP{" "}
            <span className="text-primary">Campaigns</span>
          </h1>
          <p className="text-muted mt-8  ">
            Explore a variety of ad formats, including images and HTML banners,
            from active campaigns. Track real-time data on impressions and
            clicks for deeper insights.
          </p>
          <div className="flex space-x-2 pt-8">
            <button
              onClick={() => {
                navigate("/register");
              }}
              className=" bg-primary hover:scale-105 transition-all duration-200 text-white font-semibold text-sm py-3 rounded-full px-6"
            >
              Advertise with us
            </button>
            <button className=" text-primary hover:text-muted transition-all duration-200  font-semibold text-sm py-2 rounded-full px-6">
              Learn more about kwanza
            </button>
          </div>
        </div>
        <div className="w-6/12">
          <div className="flex items-end ">
            <div className="w-7/12 flex justify-center items-center ">
              <img src="/phone.png" className=" h-[50vh]" />
            </div>
            <div className="w-5/12 ">
              <div className="flex space-x-4">
                <div className="w-6/12 h-24  text-white text-4xl flex justify-center items-center bg-primary rounded-lg">
                  <MdOutlineAdsClick className=" animate-spin " />
                </div>
                <div className="w-6/12 h-24 bg-primary text-4xl text-primary flex justify-center items-center bg-opacity-10 rounded-lg">
                  <MdSsidChart />
                </div>
              </div>
              <div className="w-full bg-background h-40  rounded-lg mb-3 mt-4 flex justify-center items-center">
                <PiImageBrokenThin className="text-7xl text-muted" />
                {/* <img src="/ad3.png" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="image" className="mt-32 text-center mb-24">
        <h1 className="text-3xl">Our Banners Types</h1>
        <div className="grid grid-cols-3 gap-24 w-9/12 2xl:w-7/12 mx-auto mt-16">
          {[
            {
              icon: <FaRegImage />,
              title: "Image Banners",
              description:
                "Static image ads designed to showcase visual content without interactive elements or calls-to-action.",
            },
            {
              icon: <BsFiletypeHtml />,
              title: "HTML Banners",
              description:
                "Dynamic ads using HTML that allow for lightweight interactivity, perfect for showcasing responsive visuals and text.",
            },
            {
              icon: <MdOndemandVideo />,
              title: "HTML Video Banners",
              description:
                "Video ads embedded within HTML, providing engaging, multimedia experiences with autoplay and looping options.",
            },
          ].map((item) => {
            return (
              <div className="flex flex-col justify-center items-center">
                <div className=" w-16 h-16 bg-primary text-3xl text-primary flex justify-center items-center bg-opacity-10 rounded-xl">
                  {item.icon}
                </div>
                <h1 className="mt-3 text-lg font-semibold">{item.title}</h1>
                <p className="text-sm text-muted">{item.description}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-between w-7/12 2xl:w-6/12 mx-auto space-x-4 items-center mb-12">
        <div className="w-6/12 space-y-2 pl-8 2xl:pl-8">
          <h1 className="text-2xl font-bold">Featured Image Banner</h1>
          <h1 className="text-sm text-muted">
            This image banner is a preview from our SSP campaign demo,
            showcasing a sample ad placement.
          </h1>
        </div>
        <div className="w-6/12 flex flex-col items-start">
          <div className="">
            <ins
              data-revive-zoneid="40"
              data-revive-id="83e0d5253d9075f0826fae43b485af6f"
            ></ins>
          </div>
        </div>
      </div>
      <div id="html" className="bg-background py-8">
        <div className="flex justify-between w-7/12 2xl:w-6/12 mx-auto space-x-4 items-center">
          <div className="w-6/12 flex flex-col items-start">
            <div className="pl-6">
              <ins
                data-revive-zoneid="39"
                data-revive-id="83e0d5253d9075f0826fae43b485af6f"
              ></ins>
            </div>
          </div>
          <div className="w-6/12 space-y-2">
            <h1 className="text-2xl font-bold">HTML Interactive Banner</h1>
            <h1 className="text-sm text-muted">
              Experience our interactive HTML banner, part of the SSP campaign
              demo, which demonstrates dynamic ad content.
            </h1>
          </div>
        </div>
      </div>

      <div className="bg-green-600 py-16  2xl:w-11/12 w-9/12 mx-auto rounded-xl mt-8">
        <div className=" text-center space-y-2 w-6/12 2xl:w-4/12  mx-auto">
          <h1 className="text-2xl font-bold text-white">
            Partner with Us for Effective Advertising
          </h1>
          <p className="text-sm text-white">
            Discover the impact of our demo SSP campaign. This banner showcases
            a sample of what your brand can achieve with our platform.
          </p>
        </div>
        <div className="flex justify-between pt-8">
          <button
            onClick={() => {
              navigate("/register");
            }}
            className="m-auto bg-orange-400 hover:scale-105 transition-all duration-200 text-white 
      font-semibold text-sm py-3 rounded-full px-6"
          >
            Get started
          </button>
        </div>
      </div>
      <div className="bg-white py-12">
        <div className="w-9/12 2xl:w-7/12 mx-auto">
          <div className=" grid grid-cols-4 mt-4">
            <div className="">
              <h1>CTAs</h1>
              <div className="flex flex-col space-y-1 mt-3">
                {[
                  { title: "Register", path: "/register" },
                  { title: "Login", path: "/login" },
                ].map((item) => (
                  <a
                    onClick={() => {
                      navigate(item.path);
                    }}
                    className="text-sm cursor-pointer text-muted"
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="">
              <h1>Our services</h1>
              <div className="flex flex-col space-y-1 mt-3">
                {[
                  { title: "DSP Campaigns", path: "" },
                  { title: "SSP Campaigns", path: "" },
                  { title: "Influencers", path: "" },
                ].map((item) => (
                  <a className="text-sm cursor-pointer text-muted">
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="">
              <h1>Contact Us</h1>
              <div className="flex flex-col space-y-1 mt-3">
                {[
                  { title: "Phone: +255627707434", path: "" },
                  { title: "Email: info@kwanza.co.tz", path: "" },
                ].map((item) => (
                  <a className="text-sm cursor-pointer text-muted">
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
            <div className="">
              <h1>Social Media</h1>
              <div className="flex items-center space-x-3 mt-3">
                <a className="text-xl cursor-pointer text-muted">
                  <FaInstagramSquare />
                </a>
                <a className="text-xl cursor-pointer text-muted">
                  <FaTwitter />
                </a>
                <a className="text-xl cursor-pointer text-muted">
                  <FaFacebook />
                </a>
                <a className="text-xl cursor-pointer text-muted">
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoPage;
