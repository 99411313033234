import { useEffect, useState } from "react";
import { useGetParams } from "../../utils/getParams";
import { getTraditionalCampaignStats } from "../../controllers/statsController";
import OverviewItem from "../../components/overviewItem";
import {
  Bs0CircleFill,
  BsCSquare,
  BsCurrencyDollar,
  BsEye,
} from "react-icons/bs";
import { BiSolidPointer } from "react-icons/bi";
import Loader from "../../components/loader";
import GroupedBarChart from "../../components/charts/groupbarChart";
import BarChart from "../../components/charts/barChart";
import PieChart from "../../components/charts/pieChart";
import DonutChart from "../../components/charts/donutChart";
import {
  AiOutlineCalendar,
  AiOutlineCheck,
  AiOutlineFilter,
} from "react-icons/ai";
import ModalRight from "../../components/modalRight";
import moment from "moment";
import Back from "../../components/back";
import { MdOutlineAdsClick } from "react-icons/md";
import { TbCloudDollar } from "react-icons/tb";
import ModalBottom from "../../components/modalBottom";
import TimeseriesChart from "../../components/charts/TimeseriesChart";
import MixedChart from "../../components/charts/mixedChart";
import { FaEarthAmericas } from "react-icons/fa6";

const TraditionalCampaignReportStats = () => {
  const params = useGetParams();
  const [loading, setLoading] = useState(true);
  const [showStory, setShowStory] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [showDistribution, setShowDistribution] = useState(false);
  const [distributionType, setDistributionType] = useState("impressions");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [data, setData] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    setLoading(true);
    const path = `${params.uuid}/?startDate=${startDate}&endDate=${endDate}`;
    getTraditionalCampaignStats(path).then((res) => {
      console.log(res);
      setData(res.data.body);
      setLoading(false);
    });
  }, [filter]);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <ModalBottom
        content={
          <div>
            {distributionType == "impressions" && (
              <div className="w-11/12 mx-auto">
                <p className="text-xl font-bold mb-1 mt-4">
                  Impressions Report
                </p>
                <p className="text-sm text-muted mb-4">
                  Impressions distribution over time
                </p>

                <TimeseriesChart
                  chartType={"area"}
                  xaxis={data.timelineStats.map((item) => item.recordedAt)}
                  yaxis={data.timelineStats.map((item) => item.impressions)}
                />
              </div>
            )}
            {distributionType == "clicks" && (
              <div className="w-11/12 mx-auto">
                <p className="text-xl font-bold mb-1 mt-4">Clicks Report</p>
                <p className="text-sm text-muted mb-4">
                  Clicks distribution over time
                </p>

                <TimeseriesChart
                  chartType={"area"}
                  xaxis={data.timelineStats.map((item) => item.recordedAt)}
                  yaxis={data.timelineStats.map((item) => item.clicks)}
                />
              </div>
            )}
            {distributionType == "AVE" && (
              <div className="w-11/12 mx-auto">
                <p className="text-xl font-bold mb-1 mt-4">AVE Report</p>
                <p className="text-sm text-muted mb-4">
                  AVE distribution over time
                </p>

                <TimeseriesChart
                  chartType={"area"}
                  xaxis={data.timelineStats.map((item) => item.recordedAt)}
                  yaxis={data.timelineStats.map((item) => item.AVE)}
                />
              </div>
            )}
            {distributionType == "spent" && (
              <div className="w-11/12 mx-auto">
                <p className="text-xl font-bold mb-1 mt-4">Spent Report</p>
                <p className="text-sm text-muted mb-4">
                  Spent distribution over time
                </p>

                <TimeseriesChart
                  chartType={"area"}
                  xaxis={data.timelineStats.map((item) => item.recordedAt)}
                  yaxis={data.timelineStats.map((item) =>
                    Math.ceil(item.spent)
                  )}
                />
              </div>
            )}
          </div>
        }
        showModal={showDistribution}
        setShowModal={() => {
          setShowDistribution(false);
        }}
      />
      <ModalRight
        showModal={showDatePicker}
        content={
          <div>
            <p className="text-xl font-bold mb-1 mt-4">Filter by Date</p>
            <p className="text-sm text-muted mb-4">Set start and end date</p>

            <div className="flex justify-between mb-4 items-center">
              <div>
                <p className="text-sm text-muted ">Start Date</p>
              </div>
              <input
                type="date"
                defaultValue={moment(startDate).format("yyy-MM-DD")}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                id="startDate"
                className=" text-sm border-mutedLight border-opacity-20 rounded-lg "
              />
            </div>
            <div className="flex justify-between mb-4 items-center">
              <div>
                <p className="text-sm text-muted ">End Date</p>
              </div>
              <input
                defaultValue={moment(endDate).format("yyy-MM-DD")}
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                id="endDate"
                className=" text-sm border-mutedLight border-opacity-20 rounded-lg "
              />
            </div>
            <button
              onClick={() => {
                setShowDatePicker(false);
                setFilter(filter + 1);
              }}
              className="py-2 px-6 font-semibold mt-4 flex space-x-2 items-center rounded-lg bg-primary text-white"
            >
              <p className="text-base">Filter Report</p>
            </button>
          </div>
        }
        setShowModal={() => {
          setShowDatePicker(false);
        }}
      />
      <Back />
      <div className="flex justify-between">
        <div className="space-y-2 mb-4">
          <h1 className="text-2xl 2xl:text-xl font-bold">Campaign stats</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Stats{" "}
            {endDate
              ? `from ${moment(startDate).format("yyy,MMM DD")} to
            ${moment(endDate).format("yyy,MMM DD")}`
              : "of All time"}
          </p>
        </div>
        <div className="">
          <button
            onClick={() => {
              setShowDatePicker(true);
            }}
            className="py-2 px-6 font-semibold flex space-x-2 items-center rounded-lg bg-primary text-white"
          >
            <AiOutlineFilter className="text-xl" />
            <p className="text-base">Filter</p>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <OverviewItem
          icon={<BsEye />}
          actionTitle={"View distribution"}
          action={() => {
            setDistributionType("impressions");
            setShowDistribution(true);
          }}
          label={"Impressions"}
          value={data.totalImpressions.toLocaleString()}
        />
        <OverviewItem
          icon={<MdOutlineAdsClick />}
          actionTitle={"View distribution"}
          action={() => {
            setDistributionType("clicks");
            setShowDistribution(true);
          }}
          label={"Clicks"}
          value={(data.totalClicks || 0).toLocaleString()}
        />
        <OverviewItem
          icon={<TbCloudDollar />}
          actionTitle={"View distribution"}
          action={() => {
            setDistributionType("AVE");
            setShowDistribution(true);
          }}
          label={"AVE"}
          value={(data.totalAVE || 0).toLocaleString()}
        />

        <OverviewItem
          icon={<BsCurrencyDollar />}
          actionTitle={"View distribution"}
          action={() => {
            setDistributionType("spent");
            setShowDistribution(true);
          }}
          label={"Spent"}
          value={(data.totalSpent || 0).toLocaleString()}
        />
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className=" col-span-8">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="font-bold text-2xl">Channel Impressions</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-6">
              Channel impressions distribution
            </p>
            <BarChart
              data1={data.channelStats.map((item) =>
                Math.ceil(item.totalImpressions)
              )}
              categories={data.channelStats.map((item) => item.channel)}
              label1={"Impressions"}
            />
          </div>
        </div>
        <div className=" col-span-4">
          <div className="bg-white p-6 rounded-xl h-full">
            <h1 className="font-bold text-2xl">Channel spent</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-6">
              Channel spend distribution
            </p>
            <DonutChart
              data={data.channelStats.map((item) => Math.ceil(item.totalSpent))}
              labels={data.channelStats.map((item) => item.channel)}
              label1={"Spent"}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className=" col-span-8">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="font-bold text-2xl">Media Impressions</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-6">
              Media impressions distribution
            </p>
            <MixedChart
              data1={data.mediaStats.map((item) =>
                Math.ceil(item.totalImpressions)
              )}
              data2={data.mediaStats.map((item) =>
                Math.ceil((item.totalClicks / item.totalImpressions) * 100)
              )}
              categories={data.mediaStats.map((item) => item.media)}
              label1={"Impressions"}
              label2={"CTR"}
            />
          </div>
        </div>
        <div className=" col-span-4">
          <div className="bg-white p-6 rounded-xl h-full">
            <h1 className="font-bold text-2xl">Media spent</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-6">
              Media spent distribution
            </p>
            <DonutChart
              data={data.mediaStats.map((item) => Math.ceil(item.totalSpent))}
              labels={data.mediaStats.map((item) => item.media)}
              label1={"Spent"}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className=" col-span-8">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="font-bold text-2xl">Channel All Clicks</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-6">
              Channel All Clicks distribution
            </p>
            <BarChart
              data1={data.channelStats.map((item) =>
                Math.ceil(item.totalAllClicks)
              )}
              categories={data.channelStats.map((item) => item.channel)}
              label1={"Impressions"}
            />
          </div>
        </div>
        <div className=" col-span-4">
          <div className="bg-white p-6 rounded-xl h-full">
            <h1 className="font-bold text-2xl">Stories</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50 pb-4">
              Stories blogs
            </p>
            <div className="space-y-2 grid grid-cols-2 gap-2">
              {Object.values(data.campaign.stories).map((item) => {
                return (
                  <div>
                    <a
                      href={item.link}
                      className=" line-clamp-1 text-sm mb-2 text-muted hover:text-primary cursor-pointer transition-all duration-200"
                      target="_blank"
                    >
                      <img className="rounded" src={item.image} />
                      <p className="mt-1">{item.link}</p>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraditionalCampaignReportStats;
