import { useLocation, useNavigate } from "react-router-dom";

const SidebarItem = ({ title, icon, path, isSidebar = true, onClick }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(path);
        }
      }}
      className={`flex space-x-3 rounded-lg px-4 items-center py-[8px] w-full ${
        pathname == path
          ? " bg-primary hover:bg-opacity-90  dark:bg-black dark:bg-opacity-30 "
          : isSidebar
          ? "hover:bg-slate-700"
          : "hover:bg-slate-50"
      }    transition-all duration-200`}
    >
      <div
        className={`text-xl   ${
          pathname == path
            ? "text-white"
            : "  dark:text-white dark:text-opacity-50"
        }`}
      >
        {icon}
      </div>
      <h1
        className={`font-semibold text-base ${
          pathname == path
            ? "  dark:text-white"
            : "  dark:text-white dark:text-opacity-50  dark:text-opacity-75"
        } `}
      >
        {title}
      </h1>
    </button>
  );
};

export default SidebarItem;
