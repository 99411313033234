import { AiOutlineDashboard, AiOutlineVideoCamera } from "react-icons/ai";
import SidebarItem from "../sidebarItem";
import { SiGoogleadsense } from "react-icons/si";
import { LiaBuysellads, LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TbAB2, TbCashRegister, TbLogs, TbReport } from "react-icons/tb";
import { BsBox2, BsInstagram, BsSignpost2 } from "react-icons/bs";
import { TiSocialInstagram } from "react-icons/ti";
import { MdPublic } from "react-icons/md";
import { RiAdvertisementLine, RiEarthLine } from "react-icons/ri";
import { LuLayoutDashboard, LuUsers } from "react-icons/lu";
import { FiBookOpen } from "react-icons/fi";
import { IoMdOptions } from "react-icons/io";
import { IoRemoveCircleOutline, IoSettings } from "react-icons/io5";
import { SlSettings } from "react-icons/sl";

const AdminSidebar = () => {
  return (
    <div className="px-4 justify-between flex flex-col h-screen text-white text-opacity-[98]">
      <div>
        {/* <h1 className="mt-4  font-bold text-2xl 2xl:text-3xl px-4 text-white">
          Kwanza
        </h1> */}
        <img src="/Kwanza 2.svg" className="h-8 mt-4" />
        <div className="pt-6">
          <SidebarItem
            icon={<LuLayoutDashboard />}
            title={"Overview"}
            path="/"
          />
        </div>
        <div className="pt-5">
          <h1 className="font-bold text-xs text-white  text-opacity-70 dark:text-white dark:text-opacity-80 mb-2">
            ACCOUNTS
          </h1>
          <SidebarItem icon={<LuUsers />} title={"Users"} path="/users" />
          <SidebarItem
            icon={<BsInstagram />}
            title={"Influencers"}
            path="/influencers"
          />
          <SidebarItem
            icon={<RiEarthLine />}
            title={"Publishers"}
            path="/publishers"
          />

          <SidebarItem icon={<BsBox2 />} title={"Agencies"} path="/agencies" />
        </div>

        <div className="pt-5">
          <h1 className="font-bold text-xs text-white  text-opacity-70 dark:text-white dark:text-opacity-80 mb-2">
            REPORTS
          </h1>

          <SidebarItem
            icon={<LiaFileInvoiceDollarSolid />}
            title={"Publisher's Invoices"}
            path="/invoices"
          />
          <SidebarItem
            icon={<AiOutlineVideoCamera />}
            title={"Channels"}
            path="/channels"
          />
        </div>
        <div className="pt-5">
          <h1 className="font-bold text-xs text-white  text-opacity-70 dark:text-white dark:text-opacity-80 mb-2">
            MANAGEMENT
          </h1>

          <SidebarItem
            icon={<IoRemoveCircleOutline />}
            title={"Unapproved Zones"}
            path="/unapproved-zones"
          />

          <SidebarItem
            icon={<SlSettings />}
            title={"Settings"}
            path="/settings"
          />
          <SidebarItem
            icon={<IoMdOptions />}
            title={"Website Categories"}
            path="/website-categories"
          />
          <SidebarItem icon={<TbLogs />} title={"Logs"} path="/logs" />
        </div>
      </div>
      {/* <button className="bg-[#3B3C65]  text-base font-semibold text-white rounded-xl py-[12px] flex justify-center items-center space-x-3  mb-8">
        <div className="text-xl">
          <FiBookOpen />
        </div>
        <div>Terms of use</div>
      </button> */}
    </div>
  );
};

export default AdminSidebar;
