import { useEffect, useRef, useState } from "react";
import { getPublishers, getUsers } from "../../controllers/userController";
import Spinner from "../../components/spinner";
import Loader from "../../components/loader";
import Pagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsSignpost2, BsTrash } from "react-icons/bs";
import { LiaBuysellads } from "react-icons/lia";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import { ImEarth } from "react-icons/im";
import { GrStatusPlaceholder } from "react-icons/gr";
import Back from "../../components/back";
import { getWebsite } from "../../controllers/websitesController";
import { getWebsiteCategories } from "../../controllers/websiteCategoriesController";
import moment from "moment";
import { getLogs } from "../../controllers/logsController";
import {
  deleteZone,
  editZone,
  getUnapprovedZones,
} from "../../controllers/zonesController";
import Switch from "../../components/switch";
import toast from "react-hot-toast";

const UnapprovedZones = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    closePopupMenu(dropdownRef, () => {
      setShowOptions(false);
    });
  }, [dropdownRef]);
  useEffect(() => {
    getData();
  }, [page, keyword]);
  const getData = () => {
    let path = `?limit=${limit}&page=${page}&keyword=${keyword}`;
    getUnapprovedZones(path).then((response) => {
      const rows = response.data.body.rows;
      const count = response.data.body.count;
      console.log(rows);
      setData(rows);
      setCount(count);
      setLoading(false);
      setCount(count);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <h1 className="text-2xl 2xl:text-xl font-bold">Unapproved Zones</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            View unaproved zones from publishers{" "}
          </p>
        </div>
      </div>
      <div className="bg-white  dark:bg-darkLight rounded-xl mt-4 ">
        <div className="bg-background dark:bg-black rounded-t-2xl bg-opacity-40 dark:bg-opacity-10 px-6 items-center py-4 flex justify-between">
          <h1 className="font-bold text-lg">
            Unapproved zones ({data.length})
          </h1>
          <input
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            className="search-style"
            placeholder="Search here"
          />
        </div>
        <div className="px-6 py-5">
          <table className="">
            <thead>
              <tr>
                <th className="">Created At</th>
                <th className="">Publisher</th>
                <th className="">Email</th>
                <th className="">Phone</th>
                <th className="">Dimensions</th>
                <th className="">zone link</th>
              </tr>
            </thead>
            <tbody className="mt-2">
              {data.map((item) => {
                return (
                  <tr>
                    <td className="">{moment(item.createdAt).fromNow()}</td>
                    <td className="">{item.Website.User.name}</td>
                    <td className="">{item.Website.User.email}</td>
                    <td className="">{item.Website.User.phone}</td>
                    <td className="">
                      {item.width}x{item.height}
                    </td>
                    <td className="text-start py-4 text-sm">
                      <a href={item.pageUrl} target="__blank">
                        Visit page
                      </a>
                    </td>
                    <td className="">
                      <button
                        className="bg-red-100 hover:bg-red-200 px-2 py-2 text-sm rounded-lg"
                        onClick={() => {
                          deleteZone(item.uuid, {
                            isApproved: !item.isApproved,
                          }).then((res) => {
                            getData();
                            toast.success("Rejected successfully");
                          });
                        }}
                      >
                        Reject Zone
                      </button>
                    </td>
                    <td className="">
                      <button
                        className="bg-green-100 hover:bg-green-200 px-2 py-2 text-sm rounded-lg"
                        onClick={() => {
                          editZone(item.uuid, {
                            isApproved: !item.isApproved,
                          }).then((res) => {
                            getData();
                            toast.success("Approved successfully");
                          });
                        }}
                      >
                        Approve Zone
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            limit={limit}
            count={count}
            setPage={setPage}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default UnapprovedZones;
