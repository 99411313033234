const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addTiktokCampaign = async (data) => {
  return await app.post("/tiktok-campaigns", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getTiktokCampaigns = async (path) => {
  return await app.get(`/tiktok-campaigns/user/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getTiktokCampaign = async (uuid) => {
  return await app.get(`/tiktok-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const downloadTiktokCampaign = async (uuid) => {
  return await app.get(`/tiktok-campaigns/pdf/?uuid=${uuid}`);
};

export const editTiktokCampaign = async (uuid, data) => {
  return await app.patch(`/tiktok-campaigns/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteTiktokCampaign = async (uuid) => {
  return await app.delete(`/tiktok-campaigns/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
