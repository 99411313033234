import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useGetParams } from "../../utils/getParams";
import Back from "../../components/back";
import {
  downloadTiktokCampaign,
  getTiktokCampaign,
} from "../../controllers/tiktokCampaignController";
import OverviewItem from "../../components/overviewItem";
import { FaUser } from "react-icons/fa";
import {
  AiOutlineLike,
  AiOutlineMessage,
  AiOutlineShareAlt,
  AiOutlineUser,
} from "react-icons/ai";
import DonutChart from "../../components/charts/donutChart";
import TimeseriesChart from "../../components/charts/TimeseriesChart";
import HorizontalBarChart from "../../components/charts/horizontalBarChart";
import BarChart from "../../components/charts/barChart";
import { deleteTiktokCampaignReport } from "../../controllers/tiktokCampaignReportController";
import NoData from "../../components/noData";
import Loader from "../../components/loader";
import { TikTokEmbed } from "react-social-media-embed";
import { UserContext } from "../../layouts/dashboardLayout";
import toast from "react-hot-toast";

const TiktokCampaignReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const params = useGetParams();
  const { user } = useContext(UserContext);
  const [data, setData] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getTiktokCampaign(params.uuid).then((res) => {
      setData(res.data.body);
      setLoading(false);
      console.log(res.data.body);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    data && (
      <div>
        <Back />
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <h1 className="text-2xl 2xl:text-xl font-bold">{params.name}</h1>
            <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
              {params.name} stats and charts
            </p>
          </div>
          {user.role == "admin" && (
            <div>
              {data.TiktokCampaignReport != null ? (
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      navigate(
                        `/edit-tiktok-campaign-report/?uuid=${params.uuid}`
                      );
                    }}
                    className="py-2 px-6 font-semibold rounded-lg transition-all duration-200 hover:bg-primary bg-dark bg-opacity-5 hover:bg-opacity-100 hover:text-white text-muted"
                  >
                    Edit Report
                  </button>
                  <button
                    onClick={() => {
                      setDownloading(true);
                      downloadTiktokCampaign(params.uuid).then((res) => {
                        console.log(res.data);
                        const url = res.data.body.url;
                        toast.success("Downloaded successfully");
                        window.open(url, "_blank");
                        setDownloading(false);
                      });
                    }}
                    className="py-2 px-6 font-semibold rounded-lg text-center transition-all duration-200 bg-primary   hover:bg-opacity-100 text-white "
                  >
                    {downloading ? "Downloading..." : "Download Report"}
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    navigate(
                      `/add-tiktok-campaign-report/?uuid=${params.uuid}`
                    );
                  }}
                  className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
                >
                  Add Report
                </button>
              )}
            </div>
          )}
        </div>
        {data.TiktokCampaignReport ? (
          <div>
            <div className="mt-2">
              <div className="grid grid-cols-4 gap-4">
                <OverviewItem
                  icon={<AiOutlineLike />}
                  showAction={false}
                  label={"Likes"}
                  value={data.TiktokCampaignReport.likes.toLocaleString()}
                />
                <OverviewItem
                  icon={<AiOutlineShareAlt />}
                  showAction={false}
                  label={"Views"}
                  value={data.TiktokCampaignReport.avarageWatchTime.toLocaleString()}
                />
                <OverviewItem
                  icon={<AiOutlineMessage />}
                  showAction={false}
                  label={"comments"}
                  value={data.TiktokCampaignReport.comments.toLocaleString()}
                />
                <OverviewItem
                  icon={<AiOutlineUser />}
                  showAction={false}
                  label={"New Followers"}
                  value={data.TiktokCampaignReport.newFollowers.toLocaleString()}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-4 mt-4">
              <div className="w-full md:w-8/12 bg-white py-8 rounded-xl  p-5">
                <div className="">
                  <h1 className="font-bold text-2xl">Audience Age</h1>
                  <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                    Audience age distributions
                  </p>
                  <HorizontalBarChart
                    chartType={"bar"}
                    data1={Object.values(
                      data.TiktokCampaignReport.audienceAgePercent
                    ).map((item) => parseInt(item))}
                    categories={Object.keys(
                      data.TiktokCampaignReport.audienceAgePercent
                    )}
                    label1={"Age"}
                  />
                </div>
              </div>
              <div className=" w-full md:w-4/12 bg-white py-8 rounded-xl  p-5">
                <h1 className="font-bold text-2xl">Audience Gender</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  Audience Gender distribution
                </p>
                <div className="mt-2">
                  <DonutChart
                    data={Object.values(
                      data.TiktokCampaignReport.audienceGenderPercent
                    ).map((item) => parseInt(item))}
                    labels={Object.keys(
                      data.TiktokCampaignReport.audienceGenderPercent
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-4 mt-4">
              <div className="w-full md:w-8/12 bg-white py-8 rounded-xl  p-5">
                <div className="">
                  <h1 className="font-bold text-2xl">Audience Region</h1>
                  <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                    Audience region distributions
                  </p>
                  <BarChart
                    chartType={"bar"}
                    data1={Object.values(
                      data.TiktokCampaignReport.audienceRegionPercent
                    ).map((item) => parseInt(item))}
                    categories={Object.keys(
                      data.TiktokCampaignReport.audienceRegionPercent
                    )}
                    label1={"Audience"}
                  />
                </div>
              </div>
              <div className=" w-full md:w-4/12 bg-white  py-8 rounded-xl  p-5">
                <h1 className="font-bold text-2xl">Other information</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50 border-b border-muted border-opacity-40 pb-1">
                  Other collected information from your campaign
                </p>

                <div className="mt-4">
                  {[
                    {
                      title: "Profile Views",
                      value:
                        data.TiktokCampaignReport.profileViews.toLocaleString(),
                    },
                    {
                      title: "Watched full video",
                      value: `${data.TiktokCampaignReport.watchedFullVideo}%`,
                    },
                    {
                      title: "Button clicks",
                      value:
                        data.TiktokCampaignReport.buttonClicks.toLocaleString(),
                    },
                    {
                      title: "Shares",
                      value: `${data.TiktokCampaignReport.shares.toLocaleString()}`,
                    },
                  ].map((item) => {
                    return (
                      <div className="grid grid-cols-12">
                        <div className=" col-span-6 text-sm text-muted">
                          {item.title}:
                        </div>

                        <div className=" col-span-6">{item.value}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {data.TiktokCampaignReport.campaignVideos && (
              <div className="bg-white p-5 rounded-lg mt-4 ">
                <h1 className="font-bold text-2xl">Campaign videos</h1>
                <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
                  View campaign videos below
                </p>
                <div className="grid grid-cols-3 mt-3">
                  {Object.values(data.TiktokCampaignReport.campaignVideos).map(
                    (item, index) => {
                      return (
                        <div
                          key={item}
                          className="bg-background bg-opacity-40 p-3 rounded-lg"
                        >
                          <TikTokEmbed url={item} width={325} />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <NoData />
        )}
      </div>
    )
  );
};

export default TiktokCampaignReport;
