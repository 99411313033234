import { useContext, useEffect, useState } from "react";
import SubmitButton from "../../components/submitButton";
import TextForm from "../../components/textForm";
import { showError } from "../../utils/showError";
import toast from "react-hot-toast";
import SelectForm from "../../components/selectForm";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getUser, registerUser } from "../../controllers/userController";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Back from "../../components/back";
import {
  addAgency,
  editAgency,
  getAgency,
} from "../../controllers/agencyController";
import Loader from "../../components/loader";

const EditAgency = () => {
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  useEffect(() => {
    getAgency(uuid).then((ref) => {
      setData(ref.data.body);
      setLoading(false);
    });
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <Back />
      <div className="space-y-2">
        <h1 className="text-2xl 2xl:text-xl font-bold">{data.name}</h1>
        <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
          Edit agency details below
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const payload = {
            name: e.target.name.value,
            email: e.target.email.value,
          };
          setUploading(true);
          editAgency(data.uuid, payload)
            .then((data) => {
              toast.success("Changed successfully");
              navigate(-1);
              setUploading(false);
            })
            .catch((err) => {
              setUploading(false);
              showError(err);
            });
        }}
        className="bg-white dark:bg-darkLight py-12 rounded-xl mt-8 w-6/12 px-8"
      >
        <div className="space-y-1">
          <h1 className="text-lg font-bold">Edit {data.name}</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            All fields are required
          </p>
        </div>
        <div className="space-y-4 my-6 mb-8">
          <TextForm
            placeholder={"Enter agency name"}
            name={"name"}
            defaultValue={data.name}
            label={"Full name"}
          />
          <TextForm
            placeholder={"Enter agency email Address"}
            name={"email"}
            defaultValue={data.email}
            label={"Agency email address"}
          />
        </div>
        <SubmitButton loading={uploading} text={`Save Changes`} />
      </form>
    </div>
  );
};

export default EditAgency;
