import { useEffect, useRef, useState } from "react";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../../components/sidebarItem";
import { BsDownload, BsTrash } from "react-icons/bs";
import { closePopupMenu } from "../../utils/closePopupMenu";
import { HiDotsVertical } from "react-icons/hi";
import moment from "moment";
import Back from "../../components/back";
import { getWebsiteCategories } from "../../controllers/websiteCategoriesController";
import { AiOutlineEdit } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import Switch from "../../components/switch";
import TextForm from "../../components/textForm";
import SubmitButton from "../../components/submitButton";
import {
  getSettings,
  updateSetting,
} from "../../controllers/settingsController";
import toast from "react-hot-toast";
import Contracts from "./Contacts";

const SettingsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    getSettings().then((response) => {
      setData(response.data.body);
      setLoading(false);
    });
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      {/* <Back /> */}
      <div className="flex justify-between items-start">
        <div className="space-y-1">
          <h1 className="text-2xl 2xl:text-xl font-bold">Settings</h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Manage system settings
          </p>
        </div>
        {selectedTab == 0 && (
          <button
            onClick={() => {
              navigate(`/add-contract`);
            }}
            className="py-2 px-6 font-semibold rounded-lg bg-primary text-white"
          >
            Add Contract
          </button>
        )}
      </div>
      <div className="flex space-x-4 text-muted text-sm mt-3 font-semibold">
        {["Contracts", "Publisher payment"].map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelectedTab(index);
              }}
              className={`${
                selectedTab == index
                  ? "border-primary text-primary"
                  : " border-transparent"
              } border-b-2  py-2 cursor-pointer`}
            >
              {item}
            </div>
          );
        })}
      </div>
      {selectedTab == 1 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setUploading(true);
            const payload = {
              amount: e.target.amount.value,
            };
            updateSetting(payload).then((res) => {
              setUploading(false);
              toast.success("Saved successfully");
              getData();
            });
          }}
          className="bg-white dark:bg-darkLight py-10 rounded-xl mt-4 w-6/12 px-8"
        >
          <div className="space-y-1">
            <h1 className="text-lg font-bold">Publisher's payment plan</h1>
          </div>
          <div className="flex space-x-4 items-center mt-3">
            <TextForm
              placeholder={"Enter amount"}
              defaultValue={data?.publisherPayment || ""}
              name={"amount"}
              label={"Payment per click/1000 impressions "}
            />
            <h1 className="mt-5">TZS</h1>
          </div>
          <SubmitButton loading={uploading} text={`Save Changes`} />
        </form>
      )}
      {selectedTab == 0 && (
        <div>
          <Contracts />
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
