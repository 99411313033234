import { useContext, useEffect, useState } from "react";
import { TbCashRegister } from "react-icons/tb";
import OverviewItem from "../../components/overviewItem";
import { BsGraphUpArrow, BsInstagram } from "react-icons/bs";
import { HiOutlineUsers } from "react-icons/hi";
import AdvertisersPage from "../accounts/advertisersPage";
import { getAgencyOverviewStats } from "../../controllers/statsController";
import { UserContext } from "../../layouts/dashboardLayout";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import ModalBottom from "../../components/modalBottom";
import AgencyDSPCampaigns from "../dspCampaigns/agencyDspCampaigns";
import AgencySSPCampaigns from "../sspCampaigns/agencySspCampaignsPage";
import AgencyActiveSSPCampaigns from "../sspCampaigns/agencyActiveSspCampaignsPage";
import AgencyActiveDSPCampaigns from "../dspCampaigns/agencyActiveDspCampaigns";

const AgencyOverviewPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [showActiveCampaigns, setshowActiveCampaigns] = useState(false);
  const [showCampaigns, setshowCampaigns] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAgencyOverviewStats(user.AgencyUser.Agency.uuid).then((res) => {
      console.log(res.data);
      setLoading(false);
      setData(res.data.body);
    });
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <ModalBottom
        showModal={showCampaigns}
        setShowModal={() => {
          setshowCampaigns(false);
        }}
        content={
          <div>
            <div className="flex  w-11/12 mx-auto justify-end">
              <div className="bg-background bg-opacity-40  px-2 py-2 rounded-full flex space-x-2 ">
                {["SSP", "DSP"].map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedCampaign(index);
                      }}
                      className={`${
                        index == selectedCampaign
                          ? "text-primary bg-primary bg-opacity-10 "
                          : "text-muted "
                      } cursor-pointer py-2 px-4 rounded-full text-sm font-medium`}
                    >
                      {item} Campaigns
                    </div>
                  );
                })}
              </div>
            </div>
            {selectedCampaign == 0 ? (
              <AgencySSPCampaigns />
            ) : (
              <AgencyDSPCampaigns />
            )}
          </div>
        }
      />
      <ModalBottom
        showModal={showActiveCampaigns}
        setShowModal={() => {
          setshowActiveCampaigns(false);
        }}
        content={
          <div>
            <div>
              <div className="flex  w-11/12 mx-auto justify-end">
                <div className="bg-background bg-opacity-40  px-2 py-2 rounded-full flex space-x-2 ">
                  {["SSP", "DSP"].map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedCampaign(index);
                        }}
                        className={`${
                          index == selectedCampaign
                            ? "text-primary bg-primary bg-opacity-10 "
                            : "text-muted "
                        } cursor-pointer py-2 px-4 rounded-full text-sm font-medium`}
                      >
                        {item} Campaigns
                      </div>
                    );
                  })}
                </div>
              </div>
              {selectedCampaign == 0 ? (
                <AgencyActiveSSPCampaigns />
              ) : (
                <AgencyActiveDSPCampaigns />
              )}
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <div className="space-y-2">
          <h1 className="text-2xl 2xl:text-xl font-bold">
            {user.AgencyUser.Agency.name}
          </h1>
          <p className="text-sm text-muted dark:text-white dark:text-opacity-50">
            Overview stats for {user.AgencyUser.Agency.name}
          </p>
        </div>

        <div>
          <button className="py-2 rounded-lg bg-primary text-white  px-4 font-bold">
            View Advertisers
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mt-4">
        <OverviewItem
          value={data.activeCampaigns}
          label={"Active Campaigns"}
          actionTitle={"View Campaigns"}
          action={() => {
            setshowActiveCampaigns(true);
          }}
          icon={<TbCashRegister />}
        />
        <OverviewItem
          value={data.campaigns}
          label={"Campaigns"}
          actionTitle={"View Campaigns"}
          action={() => {
            setshowCampaigns(true);
          }}
          icon={<BsGraphUpArrow />}
        />
        <OverviewItem
          value={data.agencyUsers}
          label={"Users"}
          actionTitle={"View Users"}
          action={() => {
            navigate(`/agency/${user.AgencyUser.Agency.uuid}/users`);
          }}
          icon={<HiOutlineUsers />}
        />
        <OverviewItem
          value={data.agencyAdvertisers}
          label={"Advertisers"}
          actionTitle={"View Advertisers"}
          action={() => {
            navigate(`/advertisers/?uuid=${user.AgencyUser.Agency.uuid}`);
          }}
          icon={<BsInstagram />}
        />
      </div>

      <AdvertisersPage />
    </div>
  );
};

export default AgencyOverviewPage;
