const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const addChannel = async (data) => {
  return await app.post("/channels", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getChannels = async (path) => {
  return await app.get(`/channels/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getChannel = async (uuid) => {
  return await app.get(`/channels/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editChannel = async (uuid, data) => {
  return await app.patch(`/channels/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteChannel = async (uuid) => {
  return await app.delete(`/channels/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
